import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { useLazyQuery } from "@apollo/react-hooks";
import { find } from "lodash";
import { DebounceForSelect } from "../../../utils/DebounceForSelect";
import { GET_PROMO_SUPPLIER } from "../../../queries/PromoQuery.js";

const PromoSupplierSelectField = ({
  name,
  value,
  valueName,
  setFieldValue,
  handleChange,
  onBlur,
  isDisable,
  highlightItem
}) => {
  const [searchVal, setSearchVal] = useState("");
  const [searchText, setSearchText] = useState("Please type to search...");
  const [flag, setFlag] = useState(true);
  const [optionsArr, setOptionsArr] = useState([]);

  const [getSupplier] = useLazyQuery(GET_PROMO_SUPPLIER, {
    fetchPolicy: "network-only",
    onCompleted: res => {
      let arr =
        res.promoSupplier.length > 0
          ? res.promoSupplier.map(option => ({
              value: option.id,
              label: option.name
            }))
          : [];

      arr.length === 0 && setSearchText("No result found.");
      setOptionsArr(arr);
    },
    onError: err => {
      console.log(err);
    }
  });

  useEffect(() => {
    flag && getSupplier(searchVal);
    setFlag(false);
  }, [flag, getSupplier, searchVal]);

  searchVal === "" &&
    value !== "" &&
    !find(optionsArr, val => val.value === value) &&
    optionsArr.push({ value: value, label: valueName });

  return (
    <Select
      options={optionsArr}
      name={name}
      value={value ? value : "1"}
      onChange={e => {
        e && setFieldValue(name, e.value);
        !e && setFieldValue(name, "");

        handleChange && handleChange(e ? e.value : "");
      }}
      className={`common-select supplier-select ${
        highlightItem ? highlightItem : ""
      }`}
      onBlur={onBlur ? onBlur : () => {}}
      onInputChange={val =>
        DebounceForSelect(
          val,
          setOptionsArr,
          setSearchVal,
          setSearchText,
          getSupplier
        )
      }
      clearable={false}
      placeholder="Please type to search..."
      noResultsText={searchText}
      onFocus={() =>
        searchText === "No result found." &&
        optionsArr.length === 0 &&
        setSearchText("Please type to search...")
      }
      disabled={isDisable ? isDisable : false}
    />
  );
};

export default PromoSupplierSelectField;

import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { useLazyQuery } from "@apollo/react-hooks";
import { GET_PRODUCT_TYPE } from "../../../queries/ProductQuery";
import { DebounceForSelect } from "../../../utils/DebounceForSelect";
import { find } from "lodash";

const ProductTypeSelect = ({ value, valueInfo, handleChange }) => {
  const [searchVal, setSearchVal] = useState("");
  const [searchText, setSearchText] = useState("Please type to search...");
  const [flag, setFlag] = useState(true);
  const [optionsArr, setOptionsArr] = useState([]);

  const [getProductType] = useLazyQuery(GET_PRODUCT_TYPE, {
    fetchPolicy: "network-only",
    onCompleted: res => {
      let arr =
        res.productType.length > 0
          ? res.productType.map(option => ({
              value: option.name,
              label: option.name
            }))
          : [];

      arr.length === 0 && setSearchText("No result found.");
      setOptionsArr(arr);
    },
    onError: err => {
      console.log(err);
    }
  });

  const getOptions = useCallback(
    value => {
      getProductType({
        variables: {
          search: value
        }
      });
    },
    [getProductType]
  );

  useEffect(() => {
    flag && getOptions(searchVal);
    setFlag(false);
  }, [flag, getOptions, searchVal, valueInfo, optionsArr]);

  return (
    <Select
      options={optionsArr}
      name="product_type"
      value={value}
      onChange={e => handleChange(e)}
      className="common-select"
      onInputChange={val =>
        DebounceForSelect(
          val,
          setOptionsArr,
          setSearchVal,
          setSearchText,
          getOptions
        )
      }
      clearable={false}
      placeholder="Please type to search..."
      noResultsText={searchText}
      onFocus={() =>
        searchText === "No result found." &&
        optionsArr.length === 0 &&
        setSearchText("Please type to search...")
      }
      multi
    />
  );
};

export default ProductTypeSelect;

import React from "react";
import CreateCashBack from "./pages/setup/cashback/CreateCashBack";
import EditCashBack from "./pages/setup/cashback/EditCashBack";
import ValidDate from "./pages/ValidDate/ValidDate";
import { retry } from "./utils/RetryLoadable";

const Reservation = React.lazy(() =>
  retry(() => import("./pages/reservation/Reservation"))
);
const CreateReservation = React.lazy(() =>
  retry(() => import("./pages/reservation/CreateReservation"))
);
const EditReservation = React.lazy(() =>
  retry(() => import("./pages/reservation/EditReservation"))
);

const Transaction = React.lazy(() =>
  retry(() => import("./pages/transaction/Transaction"))
);
const EditTransaction = React.lazy(() =>
  retry(() => import("./pages/transaction/EditTransaction"))
);

const Product = React.lazy(() =>
  retry(() => import("./pages/products/Products"))
);
const EditProduct = React.lazy(() =>
  retry(() => import("./pages/products/EditProduct"))
);

const Task = React.lazy(() => retry(() => import("./pages/task/Task")));

const People = React.lazy(() => retry(() => import("./pages/people/People")));
const CreatePeople = React.lazy(() =>
  retry(() => import("./pages/people/form/CreatePeople"))
);
const EditPeople = React.lazy(() =>
  retry(() => import("./pages/people/form/EditPeople"))
);

const Company = React.lazy(() =>
  retry(() => import("./pages/company/Company"))
);
const CreateCompany = React.lazy(() =>
  retry(() => import("./pages/company/form/CreateCompany"))
);
const EditCompany = React.lazy(() =>
  retry(() => import("./pages/company/form/EditCompany"))
);

const Account = React.lazy(() =>
  retry(() => import("./pages/account/Account"))
);
const EditCompanyAccount = React.lazy(() =>
  retry(() => import("./pages/account/EditCompanyAccount"))
);

const Campaigns = React.lazy(() =>
  retry(() => import("./pages/setup/Campaigns"))
);
const CreateCampaign = React.lazy(() =>
  retry(() => import("./pages/setup/campaign/CreateCampaign"))
);
const EditCampaign = React.lazy(() =>
  retry(() => import("./pages/setup/campaign/EditCampaign"))
);

const Promotions = React.lazy(() =>
  retry(() => import("./pages/setup/promotion/Promotions"))
);
const CreatePromotion = React.lazy(() =>
  retry(() => import("./pages/setup/promotion/CreatePromotion"))
);
const EditPromotion = React.lazy(() =>
  retry(() => import("./pages/setup/promotion/EditPromotion"))
);
const PromoCode = React.lazy(() =>
  retry(() => import("./pages/setup/PromoCode"))
);
const CashBack = React.lazy(() =>
  retry(() => import("./pages/setup/CashBack"))
);
const CreatePromoCode = React.lazy(() =>
  retry(() => import("./pages/setup/promo_code/CreatePromoCode"))
);
const EditPromoCode = React.lazy(() =>
  retry(() => import("./pages/setup/promo_code/EditPromoCode"))
);

const ProductType = React.lazy(() =>
  retry(() => import("./pages/setup/ProductType"))
);
const CreateProductType = React.lazy(() =>
  retry(() => import("./pages/setup/product_type/CreateProductType"))
);
const EditProductType = React.lazy(() =>
  retry(() => import("./pages/setup/product_type/EditProductType"))
);

const PaymentMethod = React.lazy(() =>
  retry(() => import("./pages/setup/PaymentMethod"))
);
const CreatePaymentMethod = React.lazy(() =>
  retry(() => import("./pages/setup/payment_method/CreatePaymentMethod"))
);
const EditPaymentMethod = React.lazy(() =>
  retry(() => import("./pages/setup/payment_method/EditPaymentMethod"))
);

const Logins = React.lazy(() => retry(() => import("./pages/setup/Logins")));
const CreateLogin = React.lazy(() =>
  retry(() => import("./pages/setup/logins/CreateLogin"))
);
const EditLogin = React.lazy(() =>
  retry(() => import("./pages/setup/logins/EditLogin"))
);

const Vendors = React.lazy(() => retry(() => import("./pages/setup/Vendors")));
const CreateVendor = React.lazy(() =>
  retry(() => import("./pages/setup/vendor/CreateVendor"))
);
const EditVendor = React.lazy(() =>
  retry(() => import("./pages/setup/vendor/EditVendor"))
);

const Operators = React.lazy(() =>
  retry(() => import("./pages/setup/Operators"))
);
const CreateOperator = React.lazy(() =>
  retry(() => import("./pages/setup/operator/CreateOperator"))
);
const EditOperator = React.lazy(() =>
  retry(() => import("./pages/setup/operator/EditOperator"))
);

const Roles = React.lazy(() => retry(() => import("./pages/setup/Roles")));
const CreateRole = React.lazy(() =>
  retry(() => import("./pages/setup/role/CreateRole"))
);
const EditRole = React.lazy(() =>
  retry(() => import("./pages/setup/role/EditRole"))
);

const Bank = React.lazy(() => retry(() => import("./pages/finance/bank/Bank")));
const CreateBank = React.lazy(() =>
  retry(() => import("./pages/finance/bank/form/CreateBank"))
);
const EditBank = React.lazy(() =>
  retry(() => import("./pages/finance/bank/form/EditBank"))
);

const BankAccount = React.lazy(() =>
  retry(() => import("./pages/finance/bank_account/BankAccount"))
);
const EditBankAccount = React.lazy(() =>
  retry(() => import("./pages/finance/bank_account/EditBankAccount"))
);

const ImportLogView = React.lazy(() =>
  retry(() => import("./pages/finance/bank_transaction/ImportLogView"))
);
const BankTransaction = React.lazy(() =>
  retry(() => import("./pages/finance/bank_transaction/BankTransaction"))
);
const CreateBankTransaction = React.lazy(() =>
  retry(() =>
    import("./pages/finance/bank_transaction/form/CreateBankTransaction")
  )
);
const EditBankTransaction = React.lazy(() =>
  retry(() =>
    import("./pages/finance/bank_transaction/form/EditBankTransaction")
  )
);

const FinancialTransaction = React.lazy(() =>
  retry(() =>
    import("./pages/finance/financial_transaction/FinancialTransaction")
  )
);
const CreateFinancialTransaction = React.lazy(() =>
  retry(() =>
    import(
      "./pages/finance/financial_transaction/form/CreateFinancialTransaction"
    )
  )
);
const EditFinancialTransaction = React.lazy(() =>
  retry(() =>
    import(
      "./pages/finance/financial_transaction/form/EditFinancialTransaction"
    )
  )
);
const FinancialMatchingTool = React.lazy(() =>
  retry(() => import("./pages/finance/matching_tool/MatchingTool"))
);
const Currency = React.lazy(() =>
  retry(() => import("./pages/finance/Currency"))
);

const TableSyncLog = React.lazy(() =>
  retry(() => import("./components/table/TableSyncLog"))
);

const PermissionsPage = React.lazy(() =>
  retry(() => import("./pages/setup/Permissions"))
);

const ReferralsPage = React.lazy(() =>
  retry(() => import("./pages/setup/Referrals"))
);

const PeopleReferralFormPage = React.lazy(() =>
  retry(() => import("./pages/setup/referrals/PeopleReferralForm"))
);

const CompanyReferralFormPage = React.lazy(() =>
  retry(() => import("./pages/setup/referrals/CompanyReferralForm"))
);

const routes = [
  { path: "/", exact: true, name: "Home" },
  {
    path: "/reservations",
    exact: true,
    name: "Reservations",
    component: Reservation
  },
  {
    path: "/reservations/create",
    exact: true,
    name: "Create Reservation",
    component: CreateReservation
  },
  {
    path: "/reservations/edit/:id",
    exact: true,
    name: `${window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    )}`,
    component: EditReservation
  },
  {
    path: "/operation-transactions",
    exact: true,
    name: "Operation Transactions",
    component: Transaction
  },
  {
    path: "/operation-transactions/edit/:id",
    exact: true,
    name: "Edit Operation Transaction",
    component: EditTransaction
  },
  {
    path: "/products",
    exact: true,
    name: "Products",
    component: Product
  },
  {
    path: "/products/edit/:id",
    exact: true,
    name: "Edit Product",
    component: EditProduct
  },
  {
    path: "/tasks",
    exact: true,
    name: "Tasks",
    component: Task
  },
  { path: "/people", exact: true, name: "People", component: People },
  {
    path: "/people/create",
    exact: true,
    name: "Create People",
    component: CreatePeople
  },
  {
    path: "/people/edit/:id",
    exact: true,
    name: "Edit People",
    component: EditPeople
  },
  { path: "/company", exact: true, name: "Company", component: Company },
  {
    path: "/company/create",
    exact: true,
    name: "Create Company",
    component: CreateCompany
  },
  {
    path: "/company/edit/:id",
    exact: true,
    name: "Edit Company",
    component: EditCompany
  },
  {
    path: "/company-accounts",
    exact: true,
    name: "Company Accounts",
    component: Account
  },
  {
    path: "/company-accounts/edit/:id",
    exact: true,
    name: "Edit Company Account",
    component: EditCompanyAccount
  },
  {
    path: "/setup/campaigns",
    exact: true,
    name: "Setup / Campaigns",
    component: Campaigns
  },
  {
    path: "/setup/campaigns/create",
    exact: true,
    name: "Create Campaign",
    component: CreateCampaign
  },
  {
    path: "/setup/campaigns/edit/:id",
    exact: true,
    name: "Edit Campaign",
    component: EditCampaign
  },
  {
    path: "/setup/promo-code",
    exact: true,
    name: "Setup / Promo Codes",
    component: PromoCode
  },

  {
    path: "/setup/promo-code/create",
    exact: true,
    name: "Create Promote Code",
    component: CreatePromoCode
  },
  {
    path: "/setup/promo-code/edit/:id",
    exact: true,
    name: "Edit Promote Code",
    component: EditPromoCode
  },
  {
    path: "/setup/cashback",
    exact: true,
    name: "Setup / CashBacks",
    component: CashBack
  },
  {
    path: "/setup/cashback/create",
    exact: true,
    name: "Create CashBack",
    component: CreateCashBack
  },
  {
    path: "/setup/cashback/edit/:id",
    exact: true,
    name: "Edit CashBack",
    component: EditCashBack
  },
  {
    path: "/setup/promotions",
    exact: true,
    name: "Setup / Promotions",
    component: Promotions
  },
  {
    path: "/setup/promotion/create",
    exact: true,
    name: "Create Promotion",
    component: CreatePromotion
  },
  {
    path: "/setup/promotion/edit/:id",
    exact: true,
    name: "Edit Promotion",
    component: EditPromotion
  },
  {
    path: "/setup/product-type",
    exact: true,
    name: "Setup / Product Type",
    component: ProductType
  },
  {
    path: "/setup/product-type/create",
    exact: true,
    name: "Create Product Type",
    component: CreateProductType
  },
  {
    path: "/setup/product-type/edit/:id",
    exact: true,
    name: "Edit Product Type",
    component: EditProductType
  },
  {
    path: "/setup/payment-method",
    exact: true,
    name: "Setup / Payment Method",
    component: PaymentMethod
  },
  {
    path: "/setup/payment-method/create",
    exact: true,
    name: "Create Payment Method",
    component: CreatePaymentMethod
  },
  {
    path: "/setup/payment-method/edit/:id",
    exact: true,
    name: "Edit Payment Method",
    component: EditPaymentMethod
  },
  {
    path: "/setup/logins",
    exact: true,
    name: "Setup / Login",
    component: Logins
  },
  {
    path: "/setup/logins/create",
    exact: true,
    name: "Create Login",
    component: CreateLogin
  },
  {
    path: "/setup/logins/edit/:id",
    exact: true,
    name: "Edit Login",
    component: EditLogin
  },
  {
    path: "/setup/vendors",
    exact: true,
    name: "Setup / Vendors",
    component: Vendors
  },
  {
    path: "/setup/vendors/create",
    exact: true,
    name: "Create Vendor",
    component: CreateVendor
  },
  {
    path: "/setup/vendors/edit/:id",
    exact: true,
    name: "Edit Vendor",
    component: EditVendor
  },
  {
    path: "/setup/operators",
    exact: true,
    name: "Setup / Operators",
    component: Operators
  },
  {
    path: "/setup/operators/create",
    exact: true,
    name: "Create Operator",
    component: CreateOperator
  },
  {
    path: "/setup/operators/edit/:id",
    exact: true,
    name: "Edit Operator",
    component: EditOperator
  },
  {
    path: "/setup/roles",
    exact: true,
    name: "Setup / Roles",
    component: Roles
  },
  {
    path: "/setup/roles/create",
    exact: true,
    name: "Create Role",
    component: CreateRole
  },
  {
    path: "/setup/roles/edit/:id",
    exact: true,
    name: "Edit Role",
    component: EditRole
  },
  {
    path: "/setup/permissions",
    exact: true,
    name: "Permissions",
    component: PermissionsPage
  },
  {
    path: "/setup/referrals",
    exact: true,
    name: "Referrals",
    component: ReferralsPage
  },
  {
    path: "/setup/referrals/peopleReferral/create",
    exact: true,
    name: "Create People Referral",
    component: PeopleReferralFormPage
  },

  {
    path: "/setup/referrals/peopleReferral/edit/:id",
    exact: true,
    name: "Edit People Referral",
    component: PeopleReferralFormPage
  },

  {
    path: "/setup/referrals/companyReferral/create",
    exact: true,
    name: "Create Company Referral",
    component: CompanyReferralFormPage
  },

  {
    path: "/setup/referrals/companyReferral/edit/:id",
    exact: true,
    name: "Edit Company Referral",
    component: CompanyReferralFormPage
  },

  {
    path: "/finance/banks",
    exact: true,
    name: "Banks",
    component: Bank
  },
  {
    path: "/finance/banks/create",
    exact: true,
    name: "Create Bank",
    component: CreateBank
  },
  {
    path: "/finance/banks/edit/:id",
    exact: true,
    name: "Edit Bank",
    component: EditBank
  },
  {
    path: "/finance/bank-accounts",
    exact: true,
    name: "Bank Accounts",
    component: BankAccount
  },
  {
    path: "/finance/bank-accounts/edit/:id",
    exact: true,
    name: "Edit Bank Account",
    component: EditBankAccount
  },
  {
    path: "/finance/bank-transactions/import-logs-view",
    exact: true,
    name: "Import Logs View",
    component: ImportLogView
  },
  {
    path: "/finance/bank-transactions",
    exact: true,
    name: "Bank Transactions",
    component: BankTransaction
  },
  {
    path: "/finance/bank-transactions/create",
    exact: true,
    name: "Create Bank Transaction",
    component: CreateBankTransaction
  },
  {
    path: "/finance/bank-transactions/edit/:id",
    exact: true,
    name: "Edit Bank Transaction",
    component: EditBankTransaction
  },
  {
    path:"/valid-date",
    exact: true,
    name: "Valid Date",
    component: ValidDate
  },
  {
    path: "/finance/financial-transactions",
    exact: true,
    name: "Financial Transactions",
    component: FinancialTransaction
  },
  {
    path: "/finance/financial-transactions/create",
    exact: true,
    name: "Create Financial Transaction",
    component: CreateFinancialTransaction
  },
  {
    path: "/finance/financial-transactions/edit/:id",
    exact: true,
    name: "Edit Financial Transaction",
    component: EditFinancialTransaction
  },
  {
    path: "/finance/financial-matching-tool",
    exact: true,
    name: "Financial Matching Tool",
    component: FinancialMatchingTool
  },
  {
    path: "/currency",
    exact: true,
    name: "Currency",
    component: Currency
  },
  {
    path: "/finance/financial-transactions/sync-log",
    exact: true,
    name: "Financial Sync Log",
    component: TableSyncLog
  },
  {
    path: "/products/sync-log",
    exact: true,
    name: "Product Sync Log",
    component: TableSyncLog
  },
  {
    path: "/operation-transactions/sync-log",
    exact: true,
    name: "Operation Transaction Sync Log",
    component: TableSyncLog
  },
  {
    path: "/finance/bank-transactions/sync-log",
    exact: true,
    name: "Bank Transaction Sync Log",
    component: TableSyncLog
  }
];

export default routes;

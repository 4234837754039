import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { useLazyQuery } from "@apollo/react-hooks";
import { DebounceForSelect } from "../../../utils/DebounceForSelect";
import { GET_OPERATOR } from "../../../queries/OperatorQuery";

const OperatorNameSelect = ({ value, handleChange }) => {
  const [searchVal, setSearchVal] = useState("");
  const [searchText, setSearchText] = useState("Please type to search...");
  const [flag, setFlag] = useState(true);
  const [optionsArr, setOptionsArr] = useState([]);

  const [getOperatorName] = useLazyQuery(GET_OPERATOR, {
    fetchPolicy: "network-only",
    onCompleted: res => {
      let arr =
        res.operators.data.length > 0
          ? res.operators.data.map(option => {
              return {
                value: option.name,
                label: option.name
              };
            })
          : [];

      arr.length === 0 && setSearchText("No result found.");
      setOptionsArr(arr);
    },
    onError: err => {
      console.log(err);
    }
  });

  const getOptions = useCallback(
    value => {
      getOperatorName({
        variables: {
          first: 30,
          page: 1,
          filter: [
            {
              field: "name",
              operator: "like",
              value: value
            }
          ]
        }
      });
    },
    [getOperatorName]
  );

  useEffect(() => {
    flag && getOptions(searchVal);
    setFlag(false);
  }, [flag, getOptions, searchVal, optionsArr]);

  let valText = value && value !== "" ? value.split(",") : null;
  valText &&
    valText.length > 0 &&
    valText[0] !== "" &&
    valText.map(val => {
      optionsArr.push({
        value: val,
        label: val
      });
      return true;
    });

  return (
    <Select
      options={optionsArr}
      name="operator"
      value={value}
      onChange={e => handleChange(e)}
      className="common-select"
      onInputChange={val =>
        DebounceForSelect(
          val,
          setOptionsArr,
          setSearchVal,
          setSearchText,
          getOptions
        )
      }
      clearable={false}
      placeholder="Please type to search..."
      noResultsText={searchText}
      onFocus={() =>
        searchText === "No result found." &&
        optionsArr.length === 0 &&
        setSearchText("Please type to search...")
      }
      multi
    />
  );
};

export default OperatorNameSelect;

import React, { useState,useEffect } from 'react';
import { Button, Col, Row, Table } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation, useQuery,useLazyQuery } from "@apollo/react-hooks";
import { CREATE_VALID_DATE, GET_VALID_DATE } from '../../queries/DateQuery.js';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import {
  toMySqlDate,
  formatDate,
  toMySqlDateTime,
  formatDateTime,
  formatDateTimeSec,
  formatValidDate
} from "../../utils/DateFormat";
import moment from 'moment';
import DatePickerField from "../../components/dates/DatePickerField";
import {  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition } from 'react-toasts';




const ValidDate = () => {
   const [productDate,setProductDate] = useState(new Date());
   const [productDateTime,setProductDateTime] = useState(setHours(setMinutes(new Date(), 30), 16));
   const [transactionDate,setTransactionDate] = useState(new Date());
   const [transactionDateTime,setTransactionDateTime] = useState(new Date());
   const [type,setType] = useState('');
   const [productInfo,setProductInfo] = useState(null);
   const [transactionInfo,setTransactionInfo] = useState(null);
   const [isOpen,setIsOpen] = useState(false);
   const [isTransOpen,setIsTransOpen] = useState(false);
   const [isApplyClicked,setIsApplyClicked] = useState(false);
   const [isTransApplyClicked,setIsTransApplyClicked] = useState(false);

   useEffect(() => {
    if(isOpen === false){
      setIsApplyClicked(false);
    }
   },[isOpen])

   useEffect(() => {
     if(isTransOpen === false){
       setIsTransApplyClicked(false);
     }
   },[isTransOpen])

   useEffect(() => {
    setIsApplyClicked(true);
    setIsTransApplyClicked(true);
   
   },[])

   const handleProductDateSelect = (date) => {
      setProductDateTime(toMySqlDateTime(date));
     setProductDate(date);
    
   }

   const handleProductDateChange = (date) => {
     setProductDateTime(toMySqlDateTime(date));
     setProductDate(date);
   
   }
   
   const handleTransactionDateChange = (date) => {
     setTransactionDate(date);
     setTransactionDateTime(toMySqlDateTime(date))
   }

   const handleTransactionDateSelect = (date) => {
     setTransactionDate(date);
     setTransactionDateTime(toMySqlDateTime(date));
   }

  const { error: error, data: data, refetch: refetch } = useQuery(
    GET_VALID_DATE,
    {
      fetchPolicy: "network-only",
      variables: {
        valid_type:'PRODUCT'
      }
    }
  );
  const { error: error2, data: data2, refetch: refetch2 } = useQuery(
    GET_VALID_DATE,
    {
      fetchPolicy: "network-only",
      variables: {
        valid_type:'TRANSACTION'
      }
    }
  );

  useEffect(() => {
   if(data?.validDate?.valid_type === "PRODUCT"){
     setProductInfo(data?.validDate);
    
   }
   if(data2?.validDate?.valid_type === "TRANSACTION"){
     setTransactionInfo(data2?.validDate);
   }
    },[data,data2]);



   const [createValidDate] = useMutation(CREATE_VALID_DATE, {
    onCompleted: res => {
      if(res?.createValidDate?.valid_type === "TRANSACTION"){
        setTransactionInfo(res?.createValidDate)
      }
      else{
        setProductInfo(res?.createValidDate);
      }
    
      ToastsStore.success(
        "Successfully added valid date",
        1000
      );
    },
    onError: error => {
      console.log("error is",error);
       ToastsStore.error(error.message.replace(/GraphQL error:/g, ""));
    }
  });

  const handleApply = (type) => {
   
    if(type === 'PRODUCT'){
    localStorage.setItem("product_selected_date", formatValidDate(productDate));
    localStorage.setItem("product_last_updated_date",toMySqlDateTime(new Date()));
    setIsApplyClicked(true);

    }
    else if(type === 'TRANSACTION'){
      localStorage.setItem("transaction_selected_date", formatValidDate(transactionDate));
      localStorage.setItem("transaction_last_updated_date",toMySqlDateTime(new Date()));
      setIsTransApplyClicked(true);
    }
    setType(type);
    createValidDate({
      variables:{
        input:{
          valid_type:type,
          valid_date:type === 'PRODUCT' ? productDateTime : transactionDateTime
        }
      }
    });
    setIsApplyClicked(false);
  }
  
    return(
      <>
       <ToastsContainer
        store={ToastsStore}
        position={ToastsContainerPosition.TOP_RIGHT}
      />
       <Table>     
        <tbody>
          <tr>
            <th scope="row">Product</th>
            <td>             
              <DatePicker
               selected={productDate}
               value={isApplyClicked && !isOpen ? localStorage.getItem("product_selected_date") : productDate}
               dateFormat="MM/dd/yyyy HH:mm:ss"
               shouldCloseOnSelect={false}
               onSelect={(e) => handleProductDateSelect(e)}
               onChange={(e) => handleProductDateChange(e)}
               className="form-control"
               timeFormat="HH:mm"
               timeIntervals={5}
               autoComplete="off"
               popperPlacement="bottom"
               selectsRange={true}
               showTimeSelect={true}
               onCalendarOpen = {() => setIsOpen(true)}
               onCalendarClose = {() => setIsOpen(false)}
               popperModifiers={{
                flip: {
                  behavior: ["bottom"] // don't allow it to flip to be above
                 },
                preventOverflow: {
                   enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                 hide: {
                    enabled: false // turn off since needs preventOverflow to be enabled
                 }
               }}
            />
            </td>
            <td> 
              <Button 
              disabled={localStorage.getItem("userRole") === "1" || localStorage.getItem("userRole") === "5" ? false : true} 
              type="button"
              color="primary" 
              onClick={() => handleApply('PRODUCT')}>
                  Apply
              </Button>
              </td>
             <td> 
               Last updated- <br />
               {localStorage.getItem("product_last_updated_date")}
                 </td>
                 <td>
                 Updated by <br />
               {productInfo?.updated_by?.email}
                 </td>
           </tr>
           <tr>
            <th scope="row">Operation Transaction</th>
            <td>             
              <DatePicker
               selected={transactionDate}
               value={isTransApplyClicked && !isTransOpen ? localStorage.getItem("transaction_selected_date") : productDate}
               dateFormat="MM/dd/yyyy HH:mm:ss"
               shouldCloseOnSelect={false}
               onSelect={(e) => handleTransactionDateSelect(e)}
               onChange={(e) => handleTransactionDateChange(e)}
               className="form-control"
               timeFormat="HH:mm"
               timeIntervals={5}
               autoComplete="off"
               popperPlacement="bottom"
               selectsRange={true}
               showTimeSelect={true}
               onCalendarOpen = {() => setIsTransOpen(true)}
               onCalendarClose = {() => setIsTransOpen(false)}
               popperModifiers={{
                flip: {
                  behavior: ["bottom"] // don't allow it to flip to be above
                 },
                preventOverflow: {
                   enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                 hide: {
                    enabled: false // turn off since needs preventOverflow to be enabled
                 }
               }}
             />
            </td>
            <td> 
              <Button  
              type="button"
              disabled={localStorage.getItem("userRole") === "1" || localStorage.getItem("userRole") === "5" ? false : true} 
              color="primary"
              onClick={() => handleApply('TRANSACTION')}>
                  Apply
              </Button>
              </td>
             <td> 
               Last updated- <br />
                 {localStorage.getItem("transaction_last_updated_date")}
                 </td>
                 <td>
                 Updated by <br />
               {transactionInfo?.updated_by?.email}
                 </td>
           </tr>
        </tbody>
      </Table>  
      </> 
    )
}


export default ValidDate;
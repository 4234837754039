export default {
  items: [
    {
      title: true,
      name: "FLYMYA",
      wrapper: {
        element: "", // required valid HTML5 element tag
        attributes: {} // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: "" // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: "Reservations",
      url: "/reservations",
      icon: "cui-list"
    },
    {
      name: "Operation Transactions",
      url: "/operation-transactions",
      icon: "cui-dollar"
    },
    {
      name: "Products",
      url: "/products",
      icon: "cil-basket"
    },
    {
      name: "Tasks",
      url: "/tasks",
      icon: "cil-check"
    },
    {
      name: "People",
      url: "/people",
      icon: "cui-people"
    },
    {
      name: "Company",
      url: "/company",
      icon: "cil-factory"
    },
    {
      name: "Company Accounts",
      url: "/company-accounts",
      icon: "cil-chart"
    },
    {
      name: "Setup",
      icon: "icon-settings",
      children: [
        {
          name: "Campaigns",
          url: "/setup/campaigns",
          icon: "cil-braille"
        },
        {
          name: "Promotions",
          url: "/setup/promotions",
          icon: "cil-library"
        },
        {
          name: "Promo Codes",
          url: "/setup/promo-code",
          icon: "cil-casino"
        },
        {
          name: "CashBacks",
          url: "/setup/cashback",
          icon: "cil-casino"
        },
        {
          name: "Product Type",
          url: "/setup/product-type",
          icon: "cui-list"
        },
        {
          name: "Payment Method",
          url: "/setup/payment-method",
          icon: "cui-dollar"
        },
        {
          name: "Logins",
          url: "/setup/logins",
          icon: "cui-user"
        },
        {
          name: "Operators",
          url: "/setup/operators",
          icon: "cui-list"
        },
        {
          name: "Roles",
          url: "/setup/roles",
          icon: "cui-people"
        },
        {
          name: "Permissions",
          url: "/setup/permissions",
          icon: "cui-lock-locked"
        },
        {
          name: "Referrals",
          url: "/setup/referrals",
          icon: "cui-list"
        }
      ]
    },
    {
      name: "Finance (WIP)",
      icon: "cil-bank",
      children: [
        {
          name: "Bank Accounts",
          url: "/finance/bank-accounts",
          icon: "cil-tablet"
        },
        {
          name: "Bank Transactions",
          url: "/finance/bank-transactions",
          icon: "cui-graph"
        },
        {
          name: "Financial Transactions",
          url: "/finance/financial-transactions",
          icon: "cil-calculator"
        },
        {
          name: "Financial Matching Tool",
          url: "/finance/financial-matching-tool",
          icon: "cil-lan"
        },
        {
          name:"Valid Date",
          url:"/valid-date",
          icon:"cil-lan"
        },
        {
          name: "Currency",
          url: "/currency",
          icon: "cui-dollar"
        }
      ]
    }
  ]
};

import { gql } from "apollo-boost";

const GET_PRODUCT_TYPE = gql`
  {
    productType {
      id
      name
      order
      created_at
    }
  }
`;

const CREATE_PRODUCT_TYPE = gql`
  mutation($input: productTypeInput) {
    createProductType(input: $input) {
      id
      name
    }
  }
`;

const UPDATE_PRODUCT_TYPE = gql`
  mutation($id: ID!, $input: productTypeInput) {
    updateProductType(id: $id, input: $input) {
      id
      name
    }
  }
`;

const GET_PRODUCT_STATUS = gql`
  {
    productStatus {
      id
      name
    }
  }
`;

const CREATE_PRODUCT_STATUS = gql`
  mutation($input: createProductStatusInput) {
    createProductStatus(input: $input) {
      id
    }
  }
`;

const UPDATE_PRODUCT_STATUS = gql`
  mutation($input: updateProductStatusInput) {
    updateProductStatus(input: $input) {
      id
    }
  }
`;

const GET_PRODUCTS = gql`
  query(
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    products(filter: $filter, first: $first, page: $page, orderBy: $orderBy) {
      data {
        id
        docstatus
        deadline
        attachments {
          id
          filename
          download_link
        }
        reservation {
          id
          reservation_people {
            id
            people {
              id
              prefix
              name
            }
          }
        }
        product_type {
          id
          name
          order
        }
        status {
          id
          name
          employee {
            id
            email
          }
          created_at
        }
        sales_category {
          id
          name
        }
        external_id
        description
        travel_date
        date_of_sale
        issued_date
        supplier {
          id
          name
          account_code
          company {
            id
            name
          }
        }
        operator {
          id
          code
          name
        }
        product_meta {
          id
          key
          value
          product {
            id
          }
          roundtrip
        }
        selling_price {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        net_base_fare {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        taxes {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        remarks
        service_fees {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        original_rate {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        gross_base {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        margin {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        margin_exchange_rate {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        net_rate {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        margin_type
        calculation_type
        margin_percent
        passengers {
          id
          people {
            id
            prefix
            name
            last_name
            phone
            email
          }
        }
        check {
          id
          name
          employee {
            id
            name
            email
          }
        }
        created_at
        updated_at
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const CREATE_PRODUCT = gql`
  mutation createProduct($input: productInput) {
    createProduct(input: $input) {
      id
      docstatus
      deadline
      reservation {
        id
        reservation_people {
          id
          people {
            id
            prefix
            name
          }
        }
      }
      product_type {
        id
        name
        order
      }
      status {
        id
        name
        employee {
          id
          email
        }
        created_at
      }
      sales_category {
        id
        name
      }
      external_id
      description
      travel_date
      date_of_sale
      issued_date
      supplier {
        id
        name
        company {
          id
          name
        }
      }
      operator {
        id
        code
        name
      }
      selling_price {
        id
        physical_amount
        physical_currency
        home_exchange_rate
        home_amount
        home_currency
        show_fare_exchange_rate
        show_fare_amount
        show_fare_currency
      }
      net_base_fare {
        id
        physical_amount
        physical_currency
        home_exchange_rate
        home_amount
        home_currency
        show_fare_exchange_rate
        show_fare_amount
        show_fare_currency
      }
      taxes {
        id
        physical_amount
        physical_currency
        home_exchange_rate
        home_amount
        home_currency
        show_fare_exchange_rate
        show_fare_amount
        show_fare_currency
      }
      remarks
      service_fees {
        id
        physical_amount
        physical_currency
        home_exchange_rate
        home_amount
        home_currency
        show_fare_exchange_rate
        show_fare_amount
        show_fare_currency
      }
      original_rate {
        id
        physical_amount
        physical_currency
        home_exchange_rate
        home_amount
        home_currency
        show_fare_exchange_rate
        show_fare_amount
        show_fare_currency
      }
      gross_base {
        id
        physical_amount
        physical_currency
        home_exchange_rate
        home_amount
        home_currency
        show_fare_exchange_rate
        show_fare_amount
        show_fare_currency
      }
      margin {
        id
        physical_amount
        physical_currency
        home_exchange_rate
        home_amount
        home_currency
        show_fare_exchange_rate
        show_fare_amount
        show_fare_currency
      }
      margin_exchange_rate {
        id
        physical_amount
        physical_currency
        home_exchange_rate
        home_amount
        home_currency
        show_fare_exchange_rate
        show_fare_amount
        show_fare_currency
      }
      net_rate {
        id
        physical_amount
        physical_currency
        home_exchange_rate
        home_amount
        home_currency
        show_fare_exchange_rate
        show_fare_amount
        show_fare_currency
      }
      product_meta {
        id
        key
        value
        product {
          id
        }
        roundtrip
      }
      margin_type
      calculation_type
      margin_percent
      passengers {
        id
        people {
          id
          prefix
          name
          phone
          email
        }
      }
      check {
        id
        name
        employee {
          id
          name
          email
        }
      }
    }
  }
`;

const DELETE_PRODUCT = gql`
  mutation($includeIds: [Int!]!) {
    deleteProduct(includeIds: $includeIds) {
      id
    }
  }
`;

const UNLINK_PRODUCT = gql`
  mutation($id: ID!) {
    unlinkProduct(id: $id) {
      id
    }
  }
`;

const PRODUCT_EXPORT = gql`
  mutation($date: Date!) {
    productExport(date: $date) {
      status
      message
    }
  }
`;
const GET_LAST_PRODUCT_EXPORT_TIME = gql`
  query {
    getLastProductExportTime {
      date
    }
  }
`;
const GET_PRODUCT_EXPORT_LOG = gql`
  query(
    $first: Int!
    $page: Int
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
  ) {
    getProductExportLog(
      filter: $filter
      page: $page
      first: $first
      orderBy: $orderBy
    ) {
      data {
        id
        object_id
        type
        action
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const CHECKED_UNCHECKED_PRODUCT = gql`
  mutation($input: checkProductInput) {
    checkProduct(input: $input) {
      id
      check {
        id
        name
        employee {
          id
          name
          email
        }
      }
    }
  }
`;

const CREATE_PNR = gql`
  mutation createFlightPnr($input: flightPnrInput) {
    createFlightPnr(input: $input) {
      product_id
      status
      message
      pnr
    }
  }
`;
const CREATE_ISSUED_PNR = gql`
  mutation createFlightPnrIssue($input: flightPnrInput) {
    createFlightPnrIssue(input: $input) {
      status
      message
      pnr
      product_id
    }
  }
`;

const SEND_REFUND_INVOICE = gql`
  mutation sendMailProduct(
    $id: ID!
    $template: String!
    $attachment_template: String
  ) {
    sendMailProduct(
      id: $id
      template: $template
      attachment_template: $attachment_template
    ) {
      id
      created_at
      updated_at
    }
  }
`;
const PRODUCT_BULK_UPDATE = gql`
  mutation {
    productBulkUpdate {
      status
      message
    }
  }
`;
const CHECK_ALL_PRODUCT = gql`
  mutation($input: checkAllProductInput) {
    checkAllProduct(input: $input) {
      id
      check_id
      check {
        id
        name
        employee {
          id
        }
      }
      status {
        id
        name
      }
    }
  }
`;
const DUPLICATE_PRODUCT = gql`
  mutation duplicateProduct($id: ID!) {
    duplicateProduct(id: $id) {
      id
    }
  }
`;
export {
  GET_PRODUCT_TYPE,
  GET_PRODUCT_STATUS,
  CREATE_PRODUCT_TYPE,
  CREATE_PRODUCT_STATUS,
  UPDATE_PRODUCT_TYPE,
  UPDATE_PRODUCT_STATUS,
  GET_PRODUCTS,
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  UNLINK_PRODUCT,
  PRODUCT_EXPORT,
  GET_LAST_PRODUCT_EXPORT_TIME,
  GET_PRODUCT_EXPORT_LOG,
  CHECKED_UNCHECKED_PRODUCT,
  CREATE_PNR,
  CREATE_ISSUED_PNR,
  SEND_REFUND_INVOICE,
  PRODUCT_BULK_UPDATE,
  CHECK_ALL_PRODUCT,
  DUPLICATE_PRODUCT
};

import React, { useEffect, useState } from "react";
import { GET_PAYMENT_METHOD } from "../../../queries/PaymentMethodQuery";
import { useLazyQuery } from "@apollo/react-hooks";
import Select from "react-select";

const PaymentMethodSelectField = ({ value, handleChange }) => {
  const [flag, setFlag] = useState(true);
  const [optionsArr, setOptionsArr] = useState([]);

  const [getPaymentMethod] = useLazyQuery(GET_PAYMENT_METHOD, {
    fetchPolicy: "network-only",
    onCompleted: res => {
      let arr =
        res.paymentMethods.length > 0
          ? res.paymentMethods.map(option => {
              return {
                value: option.name,
                label: option.name
              };
            })
          : [];
      setOptionsArr(arr);
    },
    onError: err => {
      console.log(err);
    }
  });

  useEffect(() => {
    flag && getPaymentMethod();
    setFlag(false);
  }, [flag, getPaymentMethod]);

  let valText = value && value !== "" ? value.split(",") : null;
  valText &&
    valText.length > 0 &&
    valText[0] !== "" &&
    valText.map(val => {
      optionsArr.push({
        value: val,
        label: val
      });
      return true;
    });

  return (
    <Select
      clearable={false}
      options={optionsArr}
      name="payment_multi"
      value={value}
      onChange={e => handleChange(e)}
      className="common-select"
      multi
    />
  );
};

export default PaymentMethodSelectField;

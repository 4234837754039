import React from "react";
import { useQuery } from "@apollo/react-hooks";
import CreateCashBack from "./CreateCashBack";
import { GET_CASHBACK } from "../../../queries/CashBackQuery";

const EditCashBack = props => {
  const id = props.match.params.id;

  const { loading, error, data } = useQuery(GET_CASHBACK, {
    fetchPolicy: "network-only",
    variables: {
      filter: [{ field: "id", operator: "is", value: id }],
      orderBy: [{ field: "id", order: "DESC" }],
      first: 1,
      page: 1
    }
  });

  if (loading) return null;
  if (error) return `Error! ${error}`;

  return <CreateCashBack editId={id} data={data} />;
};

export default EditCashBack;

export const TOKEN_TYPE = "token_type";
export const AUTH_TOKEN = "access_token";
export const LOGIN_USER_ID = "userId";
export const LOGIN_TYPE = "loginType";
export const LOGIN_FIRST_TIME = "isLoginFirst";
export const IDENTIFIER = "identifier";

export const LOGIN_TYPE_OPTIONS = [
  { value: 3, label: "Group Identifier" },
  { value: 4, label: "Identifier & Password" }
];

export const PEOPLE_PREFIX = [
  { value: "Mr", label: "Mr." },
  { value: "Mrs", label: "Mrs." },
  { value: "Ms", label: "Ms." },
  { value: "Miss", label: "Miss" },
  { value: "MSTR", label: "MSTR." },
  // { value: "INF", label: "INF." },
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" }
  // { value: "Child", label: "Child" },
  // { value: "Infant", label: "Infant" }
];

// Local storage key for "Sync" and "Export" button disable time.
export const SYNC_EXPORT_BTN_DISABLE = "SyncExportBtnDisable";
export const SYNC_DOWNLOAD_BTN_DISABLE = "SyncDownloadBtnDisable";

export const FINANCE_MATCHING_PAGE = "finance-matching";
export const PERMISSION_V3 = [
  { id: "1", type: "menu", name: "Home/Search" },
  { id: "2", type: "submenu", name: "Top Menu" },
  { id: "3", type: "field", name: "Flights" },
  { id: "4", type: "field", name: "Hotels" },
  { id: "5", type: "field", name: "Hotels (Offline)" },
  { id: "6", type: "field", name: "Hotels (Online)" },
  { id: "7", type: "field", name: "Buses" },
  { id: "8", type: "field", name: "Car Rentals" },
  { id: "9", type: "field", name: "Balloons" },
  { id: "10", type: "field", name: "Promotions" },
  { id: "11", type: "field", name: "Blog" },
  { id: "12", type: "field", name: "Login" },
  { id: "13", type: "field", name: "Promotion Slider" },
  { id: "14", type: "menu", name: "Select" },
  { id: "15", type: "submenu", name: "Pricing" },
  { id: "16", type: "field", name: "Selling price" },
  { id: "17", type: "field", name: "Original rate" },
  { id: "18", type: "field", name: "Flight details" },
  { id: "19", type: "field", name: "Fare packages" },
  { id: "20", type: "field", name: "Fare breakdown selling price" },
  { id: "21", type: "field", name: "$GB" },
  { id: "22", type: "field", name: "$NB" },
  { id: "23", type: "field", name: "$TF" },
  { id: "24", type: "field", name: "$SF" },
  { id: "25", type: "field", name: "Fare breakdown showfare" },
  { id: "26", type: "field", name: "$GB" },
  { id: "27", type: "field", name: "$TF" },
  { id: "28", type: "menu", name: "Customer Details" },
  { id: "29", type: "submenu", name: "Contact Details" },
  { id: "30", type: "field", name: "Title" },
  { id: "31", type: "field", name: "First Name + Middle Name" },
  { id: "32", type: "field", name: "Last Name" },
  { id: "33", type: "field", name: "Mobile Number" },
  { id: "34", type: "field", name: "Email" },
  { id: "35", type: "field", name: "Company name (Optional)" },
  { id: "36", type: "submenu", name: "Traveller" },
  { id: "37", type: "field", name: "Same as contact info" },
  { id: "38", type: "field", name: "Title" },
  { id: "39", type: "field", name: "First Name + Middle Name" },
  { id: "40", type: "field", name: "Last Name" },
  { id: "41", type: "field", name: "Mobile Number" },
  { id: "42", type: "field", name: "Email" },
  { id: "43", type: "field", name: "Date of Birth" },
  { id: "44", type: "field", name: "NRC" },
  { id: "45", type: "field", name: "Country of Passport/ID" },
  { id: "46", type: "field", name: "Region" },
  { id: "47", type: "field", name: "Township" },
  { id: "48", type: "field", name: "Address" },
  { id: "49", type: "field", name: "Save details for later" },
  { id: "50", type: "menu", name: "Payment" },
  { id: "51", type: "submenu", name: "Gateways" },
  { id: "52", type: "field", name: "KBZPay" },
  { id: "53", type: "field", name: "Credit/Debit Card" },
  { id: "54", type: "field", name: "KBZ Quick Pay" },
  { id: "55", type: "field", name: "Aya Bill Payments" },
  { id: "56", type: "field", name: "Wave Bill Payments" },
  { id: "57", type: "field", name: "Cash on Delivery" },
  { id: "58", type: "field", name: "Bank Transfer / Mobile Banking" },
  { id: "59", type: "field", name: "Wave Pay" },
  { id: "60", type: "field", name: "MPU Payment" },
  { id: "61", type: "field", name: "123 OTC Payments" },
  { id: "62", type: "field", name: "Paypal" },
  { id: "63", type: "field", name: "M-Pitesan" },
  { id: "64", type: "field", name: "OnePay" },
  { id: "65", type: "field", name: "Citizens Pay" },
  { id: "66", type: "field", name: "Visa/Master Payments" },
  { id: "67", type: "field", name: "MPT Fast Pay" },
  { id: "68", type: "field", name: "AYA Pay" },
  { id: "69", type: "field", name: "Balance" },
  { id: "70", type: "field", name: "Promo code" }
];

export const PERMISSION_CLASSES = [
  { value: "Guest", label: "Guest" },
  { value: "B2C", label: "B2C" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
  { value: "N", label: "N" }
];

import {
  ADD_RESERVATION_PEOPLE,
  EDIT_RESERVATION_PEOPLE,
  REMOVE_RESERVATION_PEOPLE,
  SET_RESERVATION_PEOPLE,
  RESET_RESERVATION_PEOPLE
} from "./types";

const addReservationPeople = people => {
  return { type: ADD_RESERVATION_PEOPLE, payload: people };
};

const editReservationPeople = (people, editIndex) => {
  return {
    type: EDIT_RESERVATION_PEOPLE,
    payload: { data: people, editIndex: editIndex }
  };
};

const removeReservationPeople = editIndex => {
  return {
    type: REMOVE_RESERVATION_PEOPLE,
    payload: editIndex
  };
};

const setReservationPeople = people => {
  return { type: SET_RESERVATION_PEOPLE, payload: people };
};

const resetReservationPeople = () => {
  return { type: RESET_RESERVATION_PEOPLE };
};

export {
  addReservationPeople,
  editReservationPeople,
  removeReservationPeople,
  setReservationPeople,
  resetReservationPeople
};

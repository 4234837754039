import React, { useState } from "react";
import { Button, Col, FormGroup, Label, Row, Input } from "reactstrap";
import { Form, withFormik } from "formik";
import Select from "react-select";
import { compose } from "recompose";
import MoneyInputField from "../../../components/fields/MoneyInputField";
import DatePickerField from "../../../components/dates/DatePickerField";
import CampaignSelectField from "../../../components/fields/CampaignSelectField";
import styled from "styled-components";
import { object, string } from "yup";
import { formatPromoDate } from "../../../utils/DateFormat";
import OperatorNameSelect from "../../setup/promo_code/OperatorNameSelect";
import PaymentMethodNameSelect from "../../setup/promo_code/PaymentMethodNameSelect";
import PromoSupplierSelectField from "../../setup/promo_code/PromoSupplierSelectField";
import ProductTypeSelect from "../../setup/promo_code/ProductTypeSelect";

const CashBackForm = ({
  values,
  isSubmitting,
  handleSubmit,
  setFieldValue,
  cancel,
  promoTypes,
  touched,
  errors
}) => {
  const [campaignValue, setCampaignValue] = useState(
    values && values.manual_campaign_id ? values.manual_campaign_id : null
  );

  return (
    <Row>
      <PromoStyle>
        <Col sm="12" xl="12">
          <Form onSubmit={handleSubmit} noValidate>
            <FormGroup>
              <Row>
                <Col xl="4">
                  <Label for="code">Cashback Name</Label>
                </Col>
                <Col xs="12" xl="8">
                  <Input
                    name="cashback"
                    id="cashback"
                    placeholder="Cashback Name"
                    value={values.cashback || ""}
                    type="text"
                    onChange={e => {
                      e && setFieldValue("cashback", e.target.value);
                    }}
                    className={
                      touched.cashback && errors.cashback
                        ? "input-required"
                        : ""
                    }
                  />
                  {touched.cashback && errors.cashback && (
                    <Label className="error-txt">{errors.cashback}</Label>
                  )}
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xl="4">
                  <Label for="code">Campaign Name</Label>
                </Col>
                <Col xs="12" xl="8">
                  <CampaignSelectField
                    name="campaign"
                    value={campaignValue}
                    handleChange={val => {
                      setCampaignValue(val);
                      setFieldValue("manual_campaign_id", val);
                    }}
                  />
                  {touched.manual_campaign_id && errors.manual_campaign_id && (
                    <Label className="error-txt">
                      {errors.manual_campaign_id}
                    </Label>
                  )}
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xl="4">
                  <Label for="operator_name">Supplier/Partner</Label>
                </Col>
                <Col xs="12" xl="8">
                  <PromoSupplierSelectField
                    name="supplier_id"
                    value={values.promo_supplier_id}
                    valueName={values.promo_supplier_name}
                    setFieldValue={setFieldValue}
                    handleChange={val => {
                      setFieldValue("promo_supplier_id", val);
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xl="4">
                  <Label for="limit">Usage Limit</Label>
                </Col>
                <Col xs="12" xl="8">
                  <Input
                    name="limit"
                    id="limit"
                    placeholder="Usage Limit"
                    value={values.limit || ""}
                    type="number"
                    onChange={e => {
                      e && setFieldValue("limit", e.target.value);
                    }}
                    className={
                      touched.limit && errors.limit ? "input-required" : ""
                    }
                  />
                  {touched.limit && errors.limit && (
                    <Label className="error-txt">{errors.limit}</Label>
                  )}
                </Col>
              </Row>
            </FormGroup>
            {/* {Number(values.promo_type_id) === 2 && ( */}
            {/* <FormGroup>
              <Row>
                <Col xl="4">
                  <Label for="currency">Currency</Label>
                </Col>
                <Col xs="12" xl="8">
                  <Select
                    options={[
                      { value: "MMK", label: "MMK" },
                      { value: "USD", label: "USD" }
                    ]}
                    value={values.currency}
                    className="common-select"
                    searchable={false}
                    clearable={false}
                    onChange={e => {
                      setFieldValue("currency", e.value);
                      setFieldValue("min_spend_amount_currency", e.value);
                      setFieldValue(
                        "citizen",
                        e.value === "MMK" ? "Local" : "Foreigner"
                      );
                    }}
                  />
                </Col>
              </Row>
            </FormGroup> */}
            {/* )} */}
            <FormGroup>
              <Row>
                <Col xl="4">
                  <Label for="amount">Cash Back Amount</Label>
                </Col>
                <Col xs="12" xl="8">
                  <MoneyInputField
                    value={values.amount}
                    decimalScale={2}
                    displayType={"input"}
                    onBlur={e => {
                      let value = e.target.value.replace(/,/g, "");
                      setFieldValue("amount", Number(value));
                    }}
                    className={
                      touched.amount && errors.amount
                        ? "input-required form-control"
                        : "form-control"
                    }
                  />
                  {touched.amount && errors.amount && (
                    <Label className="error-txt">{errors.amount}</Label>
                  )}
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xl="4">
                  <Label for="amount">Cash Back Currency</Label>
                </Col>
                <Col xs="12" xl="8">
                  <Select
                    options={[
                      { value: "MMK", label: "MMK" },
                      { value: "USD", label: "USD" }
                    ]}
                    value={values.currency}
                    className="common-select"
                    searchable={false}
                    clearable={false}
                    onChange={e => {
                      setFieldValue("currency", e.value);
                      setFieldValue(
                        "citizen",
                        e.value === "MMK" ? "Local" : "Foreigner"
                      );
                    }}
                    // disabled={values.currency !== "" ? true : false}
                  />
                  {touched.currency && errors.currency && (
                    <Label className="error-txt">{errors.currency}</Label>
                  )}
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xl="4">
                  <Label for="amount">Source Fee Limit</Label>
                </Col>
                <Col xs="12" xl="8">
                  <MoneyInputField
                    value={values.source_fee_limit}
                    decimalScale={0}
                    displayType={"input"}
                    onBlur={e => {
                      let value = e.target.value.replace(/,/g, "");
                      setFieldValue("source_fee_limit", Number(value));
                    }}
                    className={
                      touched.source_fee_limit && errors.source_fee_limit
                        ? "input-required form-control"
                        : "form-control"
                    }
                  />
                  {touched.source_fee_limit && errors.source_fee_limit && (
                    <Label className="error-txt">
                      {errors.source_fee_limit}
                    </Label>
                  )}
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xl="4">
                  <Label for="amount">Source Amount</Label>
                </Col>
                <Col xs="12" xl="8">
                  <MoneyInputField
                    value={values.source_fee}
                    decimalScale={2}
                    displayType={"input"}
                    onBlur={e => {
                      let value = e.target.value.replace(/,/g, "");
                      setFieldValue("source_fee", Number(value));
                    }}
                    className={
                      touched.source_fee && errors.source_fee
                        ? "input-required form-control"
                        : "form-control"
                    }
                  />
                  {touched.source_fee && errors.source_fee && (
                    <Label className="error-txt">{errors.source_fee}</Label>
                  )}
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xl="4">
                  <Label for="amount">Source Amount Currency</Label>
                </Col>
                <Col xs="12" xl="8">
                  <Select
                    options={[
                      { value: "MMK", label: "MMK" },
                      { value: "USD", label: "USD" }
                    ]}
                    value={values.source_fee_currency}
                    className="common-select"
                    searchable={false}
                    clearable={false}
                    onChange={e => {
                      setFieldValue("source_fee_currency", e.value);
                      setFieldValue(
                        "citizen",
                        e.value === "MMK" ? "Local" : "Foreigner"
                      );
                    }}
                    // disabled={values.source_fee_currency !== "" ? true : false}
                  />
                </Col>
                {touched.source_fee_currency && errors.source_fee_currency && (
                  <Label className="error-txt">
                    {errors.source_fee_currency}
                  </Label>
                )}
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xl="4">
                  <Label for="valid-date">Valid Booking Date</Label>
                </Col>
                <Col xs="4" xl="4">
                  <DatePickerField
                    name="booking_date_from"
                    fieldName="booking_date_from"
                    value={values.booking_date_from}
                    className={
                      touched.booking_date_from && errors.booking_date_from
                        ? "input-required form-control"
                        : "form-control"
                    }
                    placeholder="Start Date"
                    handleBlur={() => {}}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                  {touched.booking_date_from && errors.booking_date_from && (
                    <Label className="error-txt">
                      {errors.booking_date_from}
                    </Label>
                  )}
                </Col>
                <Col xs="4" xl="4">
                  <DatePickerField
                    name="booking_date_to"
                    fieldName="booking_date_to"
                    value={values.booking_date_to}
                    className={
                      touched.booking_date_to && errors.booking_date_to
                        ? "input-required form-control"
                        : "form-control"
                    }
                    placeholder="End Date"
                    handleBlur={() => {}}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                  {touched.booking_date_to && errors.booking_date_to && (
                    <Label className="error-txt">
                      {errors.booking_date_to}
                    </Label>
                  )}
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xl="4">
                  <Label for="valid-date">Valid Traval Date</Label>
                </Col>
                <Col xs="4" xl="4">
                  <DatePickerField
                    name="travel_date_from"
                    fieldName="travel_date_from"
                    value={values.travel_date_from}
                    className="form-control"
                    placeholder="Start Date"
                    handleBlur={() => {}}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </Col>
                <Col xs="4" xl="4">
                  <DatePickerField
                    name="travel_date_to"
                    fieldName="travel_date_to"
                    value={values.travel_date_to}
                    className="form-control"
                    placeholder="End Date"
                    handleBlur={() => {}}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xl="4">
                  <Label for="valid-date">Product Type</Label>
                </Col>
                <Col xs="12" xl="8">
                  <ProductTypeSelect
                    value={values.product_type}
                    valueInfo={values.product_type}
                    name="product_type"
                    handleChange={v =>
                      setFieldValue(
                        "product_type",
                        v.map(item => item.value)
                      )
                    }
                  />
                  {/* <ProductTypeSelect
                    value={values.product_type_id}
                    valueInfo={values.product_type}
                    name="specific_airline"
                    handleChange={e => {
                      setFieldValue(
                        "product_type_id",
                        e ? e.map(item => item.value).join(", ") : ""
                      );
                      // setFieldValue(
                      //   "product_type_name",
                      //   e ? e.map(item => item.label).join(", ") : ""
                      // );
                      console.log(values.product_type_id);
                    }}
                  /> */}
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xl="4">
                  <Label for="operator_name">Specific Operator</Label>
                </Col>
                <Col xs="12" xl="8">
                  <OperatorNameSelect
                    value={values.operator_name}
                    name="specific_airline"
                    handleChange={e => {
                      setFieldValue(
                        "operator_name",
                        e ? e.map(item => item.value).join(", ") : ""
                      );
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xl="4">
                  <Label for="operator_segment">Specific Flight</Label>
                </Col>
                <Col xs="12" xl="8">
                  <Input
                    name="operator_segment"
                    id="operator_segment"
                    placeholder="Specific Flight"
                    value={values.operator_segment || ""}
                    onChange={e => {
                      e && setFieldValue("operator_segment", e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xl="4">
                  <Label for="payment_method">Payment Methods</Label>
                </Col>
                <Col xs="12" xl="8">
                  <PaymentMethodNameSelect
                    value={values.payment_method}
                    name="specific_airline"
                    handleChange={e => {
                      setFieldValue(
                        "payment_method",
                        e ? e.map(item => item.value).join(", ") : ""
                      );
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xl="4">
                  <Label for="unique usage limit">
                    Unique Email Usage Limit
                  </Label>
                </Col>
                <Col xs="12" xl="8">
                  <Input
                    name="email_usage_limit "
                    id="email_usage_limit"
                    type="number"
                    placeholder="Unique email usage limit"
                    value={values.email_usage_limit || ""}
                    onChange={e => {
                      e && setFieldValue("email_usage_limit", e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xl="4">
                  <Label for="nationality">Nationality</Label>
                </Col>
                <Col xs="12" xl="8">
                  <Select
                    options={[
                      { value: "Local", label: "Local" },
                      { value: "Foreigner", label: "Foreigner" }
                    ]}
                    value={values.citizen}
                    className="common-select"
                    searchable={false}
                    clearable={true}
                    onChange={e => setFieldValue("citizen", e ? e.value : null)}
                    disabled={Number(values.promo_type_id) === 2 ? true : false}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xl="4">
                  <Label for="spend_amount">Min Spend Amount</Label>
                </Col>
                <Col xs="12" xl="8">
                  <MoneyInputField
                    value={values.min_spend_amount}
                    decimalScale={2}
                    displayType={"input"}
                    onBlur={e => {
                      let value = e.target.value.replace(/,/g, "");
                      setFieldValue("min_spend_amount", Number(value));
                    }}
                    className="form-control"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xl="4">
                  <Label for="spend_currency">Min Spend Currency</Label>
                </Col>
                <Col xs="12" xl="8">
                  <Select
                    options={[
                      { value: "MMK", label: "MMK" },
                      { value: "USD", label: "USD" }
                    ]}
                    value={values.min_spend_amount_currency}
                    className="common-select"
                    searchable={false}
                    clearable={false}
                    onChange={e => {
                      setFieldValue("min_spend_amount_currency", e.value);
                      setFieldValue(
                        "citizen",
                        e.value === "MMK" ? "Local" : "Foreigner"
                      );
                    }}
                    // disabled={
                    //   values.min_spend_amount_currency !== "" ? true : false
                    // }
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xl="4">
                  <Label for="description">Description</Label>
                </Col>
                <Col xs="12" xl="8">
                  <Input
                    name="description"
                    id="description"
                    placeholder="Descripiton"
                    value={values.description || ""}
                    onChange={e => {
                      e && setFieldValue("description", e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col>
                  <br />
                  <Button
                    type="submit"
                    color="primary"
                    className="  submit-btn"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Wait..." : "Submit"}
                  </Button>
                  <br />
                  <Button
                    color="danger"
                    className="delete-btn"
                    onClick={() => cancel()}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </Col>
      </PromoStyle>
    </Row>
  );
};

const ValidateMethod = () => {
  return object().shape({
    cashback: string().required("Cashback is required."),
    amount: string().required("Amount is required."),
    // promo_type_id: string().required("Promo type is required."),
    booking_date_from: string().required("Booking date from is required."),
    booking_date_to: string().required("Booking date to is required.")
  });
};

const PromoStyle = styled.div`
  width: 100%;
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

export default compose(
  withFormik({
    mapPropsToValues: props => {
      return {
        id: props.value ? props.value.id : null,
        cashback: props.value ? props.value.cashback : "",
        source_fee: props.value ? props.value.source_fee : "",
        source_fee_currency: props.value ? props.value.source_fee_currency : "",
        source_fee_limit: props.value ? props.value.source_fee_limit : "",
        // promo_type_id: props.value
        //   ? props.value.promo_type
        //     ? props.value.promo_type.id
        //     : ""
        //   : "",
        limit: props.value ? props.value.limit : "",
        // one_time_usage: props.value ? Number(props.value.one_time_usage) : 0,
        amount: props.value ? props.value.amount : "",
        booking_date_from: props.value
          ? formatPromoDate(props.value.booking_date_from)
          : "",
        booking_date_to: props.value
          ? formatPromoDate(props.value.booking_date_to)
          : "",
        travel_date_from:
          props.value && props.value.travel_date_from
            ? formatPromoDate(props.value.travel_date_from)
            : null,
        travel_date_to:
          props.value && props.value.travel_date_to
            ? formatPromoDate(props.value.travel_date_to)
            : null,
        operator_name: props.value ? props.value.operator_name : "",
        payment_method: props.value ? props.value.payment_method : "",
        operator_segment: props.value ? props.value.operator_segment : "",
        email: props.value ? props.value.email : "",
        citizen: props.value ? props.value.citizen : null,
        currency: props.value ? props.value.currency : "",
        min_spend_amount: props.value ? props.value.min_spend_amount : "",
        min_spend_amount_currency: props.value
          ? props.value.min_spend_amount_currency
          : "",
        description: props.value ? props.value.description : "",
        promo_supplier_id: props.value
          ? props.value.promo_supplier
            ? props.value.promo_supplier.id
            : "1"
          : "",
        manual_campaign_id: props.value
          ? props.value.manual_campaign
            ? props.value.manual_campaign.id
            : ""
          : "",
        promo_supplier_name:
          props.value && props.value.promo_supplier
            ? props.value.promo_supplier.name
            : "",

        product_type: props.value
          ? props.value.product_type
            ? props.value.product_type
            : ""
          : "",
        email_usage_limit: props.value
          ? props.value.email_usage_limit
            ? props.value.email_usage_limit
            : ""
          : ""
      };
    },
    validationSchema: ValidateMethod,
    handleSubmit(values, { props }) {
      props.createCashback({
        variables: {
          input: {
            id: values.id,
            cashback: values.cashback,
            source_fee: values.source_fee,
            source_fee_currency: values.source_fee_currency,
            source_fee_limit: values.source_fee_limit,
            limit: values.limit,
            amount: values.amount,
            booking_date_from: `${values.booking_date_from} 00:00:00`,
            booking_date_to: `${values.booking_date_to} 00:00:00`,
            travel_date_from: values.travel_date_from
              ? `${values.travel_date_from} 00:00:00`
              : null,
            travel_date_to: values.travel_date_to
              ? `${values.travel_date_to} 00:00:00`
              : null,
            email: values.email,
            phone: values.phone,
            operator_name: values.operator_name,
            operator_segment: values.operator_segment,
            citizen: values.citizen,
            currency: values.currency,
            payment_method: values.payment_method,
            min_spend_amount: values.min_spend_amount,
            min_spend_amount_currency: values.min_spend_amount_currency,
            description: values.description,
            promo_supplier_id: values.promo_supplier_id
              ? values.promo_supplier_id
              : "1",
            // one_time_usage: "",
            product_type: values.product_type.toString(),
            manual_campaign_id: values.manual_campaign_id,
            email_usage_limit: values.email_usage_limit
          }
        }
      });
    }
  })
)(CashBackForm);

import { gql } from "apollo-boost";

const GET_USER = gql`
  query(
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
    $first: Int!
    $page: Int
  ) {
    users(filter: $filter, orderBy: $orderBy, first: $first, page: $page) {
      data {
        id
        name
        email
        phone
        company {
          id
          name
        }
        group_accounts {
          id
          name
          email
        }
        group_users {
          id
          email
        }
        roles {
          id
          name
        }
        login_type {
          id
          name
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const GET_USER_BY_ID = gql`
  query($id: ID!) {
    user(id: $id) {
      id
      email
      phone
      company {
        id
        name
      }
      group_users {
        id
        email
      }
      roles {
        id
        name
        permissions {
          id
          name
        }
      }
      login_type {
        id
        name
      }
    }
  }
`;

const CREATE_USER = gql`
  mutation($input: RegisterInput) {
    createUser(input: $input) {
      tokens {
        user {
          id
          email
        }
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation($input: updateUserInput) {
    updateUser(input: $input) {
      id
      email
    }
  }
`;

const DELETE_USER = gql`
  mutation($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation($input: resetPasswordInput) {
    resetPassword(input: $input) {
      id
      name
      email
    }
  }
`;

const UPSERT_USERPROFILE = gql`
  mutation($input: userPofileInput!) {
    upsertUserProfile(input: $input) {
      id
      email
    }
  }
`;

const DELETE_USERPROFILE = gql`
  mutation($id: ID!) {
    deleteUserProfile(id: $id) {
      id
    }
  }
`;

export {
  GET_USER,
  GET_USER_BY_ID,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  CHANGE_PASSWORD,
  UPSERT_USERPROFILE,
  DELETE_USERPROFILE
};

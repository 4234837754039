import { gql } from "apollo-boost";

const GET_PROMO = gql`
  query(
    $first: Int!
    $page: Int
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
  ) {
    promo(orderBy: $orderBy, first: $first, page: $page, filter: $filter) {
      data {
        id
        code
        limit
        one_time_usage
        amount
        booking_date_from
        booking_date_to
        travel_date_from
        travel_date_to
        email
        phone
        operator_name
        operator_segment
        citizen
        used
        currency
        payment_method
        min_spend_amount
        min_spend_amount_currency
        description
        promo_type {
          id
          name
        }
        promo_supplier {
          id
          name
        }
        manual_campaign {
          id
          name
        }
        product_type
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const CREATE_PROMO = gql`
  mutation createPromo($input: promoInput) {
    createPromo(input: $input) {
      id
      code
      limit
      amount
      currency
      booking_date_from
      booking_date_to
      travel_date_from
      travel_date_to
      email
      phone
      operator_name
      operator_segment
      citizen
      payment_method
      min_spend_amount
      min_spend_amount_currency
      description
      promo_type {
        id
        name
      }
      promo_supplier {
        id
        name
      }
      manual_campaign {
        id
        name
      }
      product_type
    }
  }
`;

const GET_PROMO_TYPE = gql`
  {
    promoType {
      id
      name
    }
  }
`;

const DELETE_PROMO_CODE = gql`
  mutation deletePromo($id: ID!) {
    deletePromo(id: $id) {
      id
      code
      limit
      amount
      currency
      booking_date_from
      booking_date_to
      travel_date_from
      travel_date_to
      email
      phone
      operator_name
      operator_segment
      citizen
      payment_method
      min_spend_amount
      min_spend_amount_currency
      description
      promo_type {
        id
        name
      }
      promo_supplier {
        id
        name
      }
      manual_campaign {
        id
        name
      }
    }
  }
`;

const GET_PROMO_SUPPLIER = gql`
  query promoSupplier {
    promoSupplier {
      id
      name
    }
  }
`;
const EXPORT_PROMO = gql`
  mutation($date: Date!, $input: promoExportInput) {
    promoExport(date: $date, input: $input) {
      status
      message
    }
  }
`;
export {
  GET_PROMO,
  CREATE_PROMO,
  GET_PROMO_TYPE,
  DELETE_PROMO_CODE,
  GET_PROMO_SUPPLIER,
  EXPORT_PROMO
};

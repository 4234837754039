import { combineReducers } from "redux";
import ReservationPeopleReducer from "./reservation/ReservationPeopleReducer";
import ReservationTabReducer from "./reservation/ReservationTabReducer";
import ReservationSubTabReducer from "./reservation/ReservationSubTabReducer";
import ReservationChildSubTabReducer from "./reservation/ReservationChildSubTabReducer";
import ReservationProductReducer from "./reservation/ReservationProductReducer";
import ReservationIdReducer from "./reservation/ReservationIdReducer";
import ReservationTransactionReducer from "./reservation/ReservationTransactionReducer";
import ReservationSourceReducer from "./reservation/ReservationSourceReducer";
import ReservationStatusReducer from "./reservation/ReservationStatusReducer";
import ReservationAssignToReducer from "./reservation/ReservationAssignToReducer";
import ReservationArrangerReducer from "./reservation/ReservationArrangerReducer";
import ReservationCheckUncheckReducer from "./reservation/ReservationCheckUncheckReducer";
import ReservationTaskReducer from "./reservation/ReservationTaskReducer";
import ReservationTotalReducer from "./reservation/ReservationTotalReducer";
import ReservationBalanceReducer from "./reservation/ReservationBalanceReducer";
import ReservationCurrencyReducer from "./reservation/ReservationCurrencyReducer";
import ReservationExchangeRateReducer from "./reservation/ReservationExchangeRateReducer";
import ReservationDocStatusReducer from "./reservation/ReservationDocStatusReducer";
import ReservationEmailStatusReducer from "./reservation/ReservationEmailStatusReducer";
import ExchangeRateArrayReducer from "./reservation/ExchangeRateArrayReducer";
import ReservationSalesChannelReducer from "./reservation/ReservationSalesChannelReducer";
import ReservationLogsReducer from "./reservation/ReservationLogsReducer";
import ReservationTransactionSaveReducer from "./reservation/ReservationTransactionSaveReducer";
import ReservationProductSaveReducer from "./reservation/ReservationProductSaveReducer";

const rootReducer = combineReducers({
  reservationPeople: ReservationPeopleReducer,
  reservationProduct: ReservationProductReducer,
  reservationTransaction: ReservationTransactionReducer,
  reservationSource: ReservationSourceReducer,
  reservationTab: ReservationTabReducer,
  reservationSubTab: ReservationSubTabReducer,
  reservationChildSubTab: ReservationChildSubTabReducer,
  reservationId: ReservationIdReducer,
  reservationStatus: ReservationStatusReducer,
  reservationAssignTo: ReservationAssignToReducer,
  reservationArranger: ReservationArrangerReducer,
  reservationCheckedUnchecked: ReservationCheckUncheckReducer,
  reservationTask: ReservationTaskReducer,
  reservationTotal: ReservationTotalReducer,
  reservationBalance: ReservationBalanceReducer,
  reservationCurrency: ReservationCurrencyReducer,
  reservationExchangeRate: ReservationExchangeRateReducer,
  reservationDocStatus: ReservationDocStatusReducer,
  reservationEmailStatus: ReservationEmailStatusReducer,
  exchangeRateArray: ExchangeRateArrayReducer,
  reservationSalesChannel: ReservationSalesChannelReducer,
  reservationLogs: ReservationLogsReducer,
  reservationTransactionSave: ReservationTransactionSaveReducer,
  reservationProductSave: ReservationProductSaveReducer
});

export default rootReducer;

import CommonReducer from "../CommonReducer";

const initialState = [];

const ReservationPeopleReducer = CommonReducer(
  initialState,
  "reservationPeople"
);

export default ReservationPeopleReducer;

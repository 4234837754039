import React, { useState } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { Col, Row, Card, CardHeader, CardBody, Table } from "reactstrap";
import CashBackForm from "./CashBackForm";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { CREATE_PROMO, GET_PROMO_TYPE } from "../../../queries/PromoQuery";
import { GET_RESERVATIONS } from "../../../queries/ReservationQuery";
import { FindDataFromArray } from "../../../utils/ValuesFromArray";
import ReactPagination from "../../../components/ReactPagination";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import LoadingSpinner from "../../../components/LoadingSpinner";
import styled from "styled-components";
import { CREATE_CASHBACK } from "../../../queries/CashBackQuery";

const CreateCashBack = ({ editId, data }) => {
  const history = useHistory();
  const [count, setCount] = useState(20);
  const [page, setPage] = useState(1);

  const [createCashback] = useMutation(CREATE_CASHBACK, {
    onCompleted: () => {
      ToastsStore.success(
        "Success! \n Cashback is created successfully!",
        1000
      );
      history.push("/setup/cashback");
    },
    onError: () => {
      console.log("error");
    },
  });

  const { loading, error, data: data2 } = useQuery(GET_PROMO_TYPE, {
    fetchPolicy: "network-only",
  });

  // const { loading: loading2, error: error2, data: res } = useQuery(
  //   GET_RESERVATIONS,
  //   {
  //     fetchPolicy: "network-only",
  //     variables: {
  //       filter: editId
  //         ? [
  //             {
  //               field: "sources.promo_id",
  //               operator: "=",
  //               value: FindDataFromArray(data.cashback.data, editId).id,
  //             },
  //           ]
  //         : [],
  //       orderBy: [
  //         {
  //           field: "created_at",
  //           order: "DESC",
  //         },
  //       ],
  //       first: 100,
  //       page: 1,
  //     },
  //   }
  // );

  // if (loading || loading2) return <LoadingSpinner />;
  // if (error) return `Error! ${error}`;
  // if (error2) return `Error! ${error2}`;

  return (
    <Row>
      <ToastsContainer
        store={ToastsStore}
        position={ToastsContainerPosition.TOP_RIGHT}
      />
      <Col lg="7" sm="7">
        <Card className="no-padding">
          <CardHeader>
            <h3 className="page-title title">Cash Back</h3>
          </CardHeader>
          <CardBody>
            {/* <PromoCodeForm
              value={editId ? FindDataFromArray(data.promo.data, editId) : null}
              promoTypes={data2.promoType}
              editId={editId}
              cancel={() => history.push("/setup/promo-code")}
              createPromo={createPromo}
            /> */}
            <CashBackForm
              value={
                editId ? FindDataFromArray(data.cashback.data, editId) : null
              }
              promoTypes={data2?.promoType}
              editId={editId}
              cancel={() => history.push("/setup/cashback")}
              createCashback={createCashback}
            />
          </CardBody>
        </Card>
      </Col>

      {/* {editId && (
        <Col sm="4" xl="4">
          <TableStyle>
            <br />
            <h5>Reservation that have used promo code</h5>
            <Table>
              <thead>
                <tr>
                  <th>Reservation ID</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody className="dataDiv">
                {res.reservations.data &&
                  res.reservations.data.length > 0 &&
                  res.reservations.data.map((val, k) => {
                    return (
                      <tr key={k}>
                        <td>
                          <NavLink
                            to={`/reservations/edit/${val.id}`}
                            className="linktext resId"
                          >
                            {val.id
                              ? val.id.replace(/^(.{2})(.{3})(.*)$/, "$1 $2 $3")
                              : ""}
                          </NavLink>
                        </td>
                        <td>{val.status ? val.status.name : ""}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </TableStyle>
        </Col>
      )} */}
    </Row>
  );
};

const TableStyle = styled.div`
  table {
    border: 1px solid rgba(0, 0, 0, 0.5);
    td {
      vertical-align: middle !important;
      width: 200px;
    }
    th {
      width: 200px;
    }
    tr {
      min-width: 100%;
      display: block;
    }
    .resId {
      font-family: "Roboto Mono";
      font-size: 15px;
    }
    .dataDiv {
      max-height: 500px;
      overflow-y: scroll;
      min-width: 100%;
      display: block;
    }
  }
`;

export default CreateCashBack;

import { gql } from "apollo-boost";

const GET_OPERATOR = gql`
  query($filter: [filterInput], $first: Int!, $page: Int) {
    operators(
      filter: $filter
      first: $first
      page: $page
      orderBy: [{ field: "id", order: DESC }]
    ) {
      data {
        id
        code
        name
        product_type {
          id
          name
        }
      }
      paginatorInfo {
        count
        currentPage
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const GET_OPERATOR_BY_PRODUCT_TYPE = gql`
  query($id: ID!) {
    operatorsByProductType(product_type_id: $id) {
      id
      code
      name
      product_type {
        id
        name
      }
    }
  }
`;

const UPDATE_OPERATOR = gql`
  mutation($input: operatorEditInput) {
    editOperator(input: $input) {
      id
      code
      name
      product_type {
        id
        name
      }
    }
  }
`;

const CREATE_OPERATOR = gql`
  mutation($input: operatorCreateInput) {
    createOperator(input: $input) {
      id
      code
      name
      product_type {
        id
        name
      }
    }
  }
`;

export {
  GET_OPERATOR,
  UPDATE_OPERATOR,
  CREATE_OPERATOR,
  GET_OPERATOR_BY_PRODUCT_TYPE
};

import { gql } from "apollo-boost";

const GET_RESERVATION_BY_ID = gql`
  query($id: ID!) {
    reservation(reservation_id: $id) {
      id
      status {
        id
        name
      }
      check {
        id
        name
        employee {
          id
          email
        }
      }
      cross_status {
        flight
        hotel
        total
      }
      products {
        id
        docstatus
        deadline
        remarks
        void_reason
        deadline
        currentCheck {
          id
          name
          employee {
            id
            email
          }
        }
        product_type {
          id
          name
          order
        }
        status {
          id
          name
          employee {
            id
            email
          }
          created_at
        }
        sales_category {
          id
          name
        }
        external_id
        group
        parent_id
        description
        travel_date
        date_of_sale
        issued_date
        supplier {
          id
          name
          company {
            id
            name
          }
        }
        operator {
          id
          code
          name
        }
        selling_price {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        net_base_fare {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        taxes {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        service_fees {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        original_rate {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        gross_base {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        margin {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        margin_exchange_rate {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        net_rate {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        margin_type
        calculation_type
        margin_percent
        passengers {
          id
          people {
            id
            locked_people {
              id
              prefix
              name
              last_name
              phone
              email
              nationality {
                id
                name
              }
              dob
              nrc
              passport
              passport_exp
            }
            login {
              id
              name
              email
              company {
                id
                name
              }
              roles {
                id
                name
              }
              group_users {
                id
                name
              }
              group_accounts {
                id
                name
              }
            }
            company {
              id
              name
            }
            profiles {
              id
              profile
            }
            sales
            prefix
            name
            last_name
            phone
            email
            nationality {
              id
              name
            }
            dob
            nrc
            passport
            passport_exp
          }
          roles {
            id
            name
          }
        }
        product_meta {
          id
          key
          value
          product {
            id
          }
          roundtrip
        }
        logs {
          id
          causer {
            id
            email
          }
          description
          updates {
            key
            old
            new
          }
          raw
          type
          created_at
        }
        attachments {
          id
          filename
          download_link
        }
        created_at
        deleted_at
      }
      transactions {
        id
        docstatus
        currentCheck {
          id
          name
          employee {
            id
            email
          }
        }
        payer {
          id
          people {
            id
            prefix
            name
            phone
            email
          }
        }
        payment_method {
          id
          name
          currencies {
            code
            name
          }
        }
        company_account {
          id
          name
          balance
          currency
          type
          account_function
          credit_type {
            id
            name
          }
          credit_remaining_days
          credit_remaining_amount
        }
        payment_method_active
        transaction_type {
          id
          name
        }
        transaction_id
        reference
        external_id
        status {
          id
          name
          employee {
            id
            email
          }
          created_at
        }
        payment_date
        remarks
        finance_remarks
        amount {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        net_base {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        customer_charge {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        logs {
          id
          causer {
            id
            email
          }
          description
          updates {
            key
            old
            new
          }
          raw
          type
          created_at
        }
        attachments {
          id
          filename
          download_link
        }
        deleted_at
        updated_at
        created_at
      }
      tasks {
        id
        employee {
          id
          email
        }
        status {
          id
          name
        }
        assigned_from {
          id
          email
        }
        requested_by {
          id
          email
        }
        logs {
          id
          causer {
            id
            email
          }
          description
          updates {
            key
            old
            new
          }
          raw
          type
          created_at
        }
        check {
          id
          name
          employee {
            id
            email
          }
          created_at
        }
        finance_remarks
        title
        linked_to_text
        key
        description
        object_type
        created_at
        deleted_at
      }
      sources {
        id
        source_type_id
        items {
          id
          key
          value
        }
        net_base {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        customer_charge {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        logs {
          id
          causer {
            id
            email
          }
          description
          updates {
            key
            old
            new
          }
          raw
          type
          created_at
        }
        deleted_at
      }
      arranger {
        id
        people {
          id
          prefix
          name
          last_name
          email
          company_people {
            id
            name
            class_type {
              id
            }
          }
        }
        roles {
          id
          name
        }
        logs {
          id
          causer {
            id
            email
          }
          description
          updates {
            key
            old
            new
          }
          raw
          type
          created_at
        }
      }
      sales_channel {
        id
        name
      }
      employee {
        id
        email
        company {
          id
          name
        }
        roles {
          id
          name
          permissions {
            id
            name
          }
        }
        group_users {
          id
          name
          email
        }
        group_accounts {
          id
          name
          email
        }
      }
      reservation_people {
        id
        people {
          id
          people_id
          login {
            id
            name
            email
          }
          company {
            id
            name
          }
          company_people {
            id
            name
            class_type {
              id
            }
          }
          profiles {
            id
            profile
          }
          sales
          prefix
          name
          last_name
          phone
          email
          nationality {
            id
            name
          }
          dob
          nrc
          passport
          passport_exp
          address
          township {
            id
            name
            city_id
            city {
              id
              name
              region_id
              region {
                id
                name
                country {
                  id
                  name
                  iso_code_2
                  iso_code_3
                  currency_code
                  nationality
                  phone
                }
              }
            }
          }
        }
        roles {
          id
          name
        }
        logs {
          id
          causer {
            id
            email
          }
          description
          updates {
            key
            old
            new
          }
          raw
          type
          created_at
        }
      }
      balance {
        id
        physical_amount
        physical_currency
        home_exchange_rate
        home_amount
        home_currency
        show_fare_exchange_rate
        show_fare_amount
        show_fare_currency
      }
      total {
        id
        physical_amount
        physical_currency
        home_exchange_rate
        home_amount
        home_currency
        show_fare_exchange_rate
        show_fare_amount
        show_fare_currency
      }
      currency
      exchange_rate
      created_at
      updated_at
      docstatus
      eticket_send
      reserve_email_send
      logs {
        id
        causer {
          id
          email
        }
        description
        updates {
          key
          old
          new
        }
        raw
        type
        created_at
      }
    }
  }
`;

const EXPORT_RESERVATION = gql`
  mutation($date: Date!, $input: optionInput) {
    reservationExport(date: $date, input: $input) {
      status
      message
    }
  }
`;

const RESERVATION_EXPORT_STATUS = gql`
  mutation($download_id: String!) {
    reservationExportStatus(download_id: $download_id) {
      status
      code
      message
    }
  }
`;

const GET_RESERVATIONS = gql`
  query(
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    reservations(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        eticket_send
        reserve_email_send
        company {
          id
          type
        }
        total {
          id
          home_amount
          show_fare_amount
          show_fare_currency
        }
        balance {
          id
          home_amount
          show_fare_amount
          show_fare_currency
        }
        currency
        exchange_rate
        created_at
        status {
          id
          name
        }
        check {
          id
          name
          employee {
            id
            email
          }
        }
        products {
          id
          parent_id
          sale_invoice_send
          refund_invoice_send
          deadline
          check_id
          currentCheck {
            id
            name
            employee {
              id
              email
            }
          }
          selling_price {
            id
            physical_amount
            physical_currency
            home_exchange_rate
            home_amount
            home_currency
          }
        }
        transactions {
          id
          check_id
          currentCheck {
            id
            name
            employee {
              id
              email
            }
          }
          amount {
            id
            home_amount
            home_currency
            physical_amount
            physical_currency
            home_exchange_rate
          }
        }
        sources {
          id
          items {
            id
            key
            value
          }
        }
        employee {
          id
          email
        }
        arranger {
          id
          people {
            id
            prefix
            name
            last_name
            email
          }
        }
        sales_channel {
          id
          name
        }
        created_by {
          id
          name
          email
        }
      }
      paginatorInfo {
        count
        currentPage
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const CREATE_RESERVATION = gql`
  mutation($input: reservationInput) {
    createReservation(input: $input) {
      id
      total {
        id
        home_amount
        show_fare_amount
        show_fare_currency
      }
      cross_status {
        flight
        hotel
        total
      }
      balance {
        id
        home_amount
        show_fare_amount
        show_fare_currency
      }
      currency
      exchange_rate
      created_at
      status {
        id
        name
      }
      check {
        id
        name
        employee {
          id
          email
        }
      }
      products {
        id
        docstatus
        deadline
        remarks
        void_reason
        currentCheck {
          id
          name
          employee {
            id
            email
          }
        }
        product_type {
          id
          name
          order
        }
        status {
          id
          name
          employee {
            id
            email
          }
          created_at
        }
        sales_category {
          id
          name
        }
        external_id
        group
        parent_id
        description
        travel_date
        date_of_sale
        issued_date
        supplier {
          id
          name
          company {
            id
            name
          }
        }
        operator {
          id
          code
          name
        }
        selling_price {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        net_base_fare {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        taxes {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        service_fees {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        original_rate {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        gross_base {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        margin {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        margin_exchange_rate {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        net_rate {
          id
          physical_amount
          physical_currency
          home_exchange_rate
          home_amount
          home_currency
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        margin_type
        calculation_type
        margin_percent
        passengers {
          id
          people {
            id
            locked_people {
              id
              prefix
              name
              last_name
              phone
              email
              nationality {
                id
                name
              }
              dob
              nrc
              passport
              passport_exp
            }
            login {
              id
              name
              email
              company {
                id
                name
              }
              roles {
                id
                name
              }
              group_users {
                id
                name
              }
              group_accounts {
                id
                name
              }
            }
            company {
              id
              name
            }
            profiles {
              id
              profile
            }
            sales
            prefix
            name
            last_name
            phone
            email
            nationality {
              id
              name
            }
            dob
            nrc
            passport
            passport_exp
          }
          roles {
            id
            name
          }
        }
        product_meta {
          id
          key
          value
          product {
            id
          }
          roundtrip
        }
        logs {
          id
          causer {
            id
            email
          }
          description
          updates {
            key
            old
            new
          }
          raw
          type
          created_at
        }
        attachments {
          id
          filename
          download_link
        }
        created_at
        deleted_at
      }
      transactions {
        id
        docstatus
        currentCheck {
          id
          name
          employee {
            id
            email
          }
        }
        payer {
          id
          people {
            id
            prefix
            name
            phone
            email
          }
        }
        payment_method {
          id
          name
          currencies {
            code
            name
          }
        }
        company_account {
          id
          name
          balance
          currency
          type
          credit_type {
            id
            name
          }
          credit_remaining_days
          credit_remaining_amount
        }
        payment_method_active
        transaction_type {
          id
          name
        }
        transaction_id
        reference
        external_id
        status {
          id
          name
          employee {
            id
            email
          }
          created_at
        }
        payment_date
        remarks
        finance_remarks
        amount {
          id
          home_amount
          home_currency
          physical_amount
          physical_currency
          home_exchange_rate
        }
        net_base {
          id
          home_amount
          home_currency
          physical_amount
          physical_currency
          home_exchange_rate
        }
        customer_charge {
          id
          home_amount
          home_currency
          physical_amount
          physical_currency
          home_exchange_rate
        }
        logs {
          id
          causer {
            id
            email
          }
          description
          updates {
            key
            old
            new
          }
          raw
          type
          created_at
        }
        attachments {
          id
          filename
          download_link
        }
      }
      reservation_people {
        id
        people {
          id
          people_id
          company {
            id
            name
          }
          company_people {
            id
            name
            class_type {
              id
            }
          }
          profiles {
            id
            profile
          }
          sales
          prefix
          name
          last_name
          phone
          email
          nationality {
            id
            name
          }
          dob
          nrc
          passport
          passport_exp
          address
          township {
            id
            name
            city_id
            city {
              id
              name
              region_id
              region {
                id
                name
                country {
                  id
                  name
                  iso_code_2
                  iso_code_3
                  currency_code
                  nationality
                  phone
                }
              }
            }
          }
        }
        roles {
          id
          name
        }
        logs {
          id
          causer {
            id
            email
          }
          description
          updates {
            key
            old
            new
          }
          raw
          type
          created_at
        }
      }
      sources {
        id
        source_type_id
        items {
          id
          key
          value
        }
        net_base {
          id
          home_amount
          home_currency
          physical_amount
          physical_currency
          home_exchange_rate
          show_fare_exchange_rate
          show_fare_amount
          show_fare_currency
        }
        customer_charge {
          id
          home_amount
          home_currency
          physical_amount
          physical_currency
          home_exchange_rate
        }
        logs {
          id
          causer {
            id
            email
          }
          description
          updates {
            key
            old
            new
          }
          raw
          type
          created_at
        }
      }
      employee {
        id
        email
      }
      arranger {
        id
        people {
          id
          prefix
          name
          last_name
          email
          company {
            name
            id
          }
          company_people {
            name
            id
            class_type {
              id
            }
          }
        }
      }
      sales_channel {
        id
        name
      }
      tasks {
        id
        employee {
          id
          email
        }
        status {
          id
          name
        }
        assigned_from {
          id
          email
        }
        requested_by {
          id
          email
        }
        logs {
          id
          causer {
            id
            email
          }
          description
          updates {
            key
            old
            new
          }
          raw
          type
          created_at
        }
        check {
          id
          name
          employee {
            id
            email
          }
          created_at
        }
        finance_remarks
        title
        description
        key
      }
      docstatus
      eticket_send
      reserve_email_send
      logs {
        id
        causer {
          id
          email
        }
        description
        updates {
          key
          old
          new
        }
        raw
        type
        created_at
      }
    }
  }
`;

const RESERVATION_TASK_CONNECTOR = gql`
  query($id: ID!) {
    taskConnector(reservation_id: $id) {
      key
      label
    }
  }
`;

const DELETE_RESERVATION = gql`
  mutation($includeIds: [ID!]!) {
    deleteReservation(includeIds: $includeIds) {
      id
    }
  }
`;

const RESERVATION_STATUS = gql`
  {
    reservationStatus {
      id
      name
    }
  }
`;

const SEND_EMAIL_RESERVATION = gql`
  mutation($id: ID!, $template: String!, $attachment_template: String) {
    sendMailReservation(
      id: $id
      template: $template
      attachment_template: $attachment_template
    ) {
      id
    }
  }
`;

const CHECK_RESERVATION = gql`
  mutation($input: checkReservationInput!) {
    checkReservation(input: $input) {
      id
      check {
        id
        name
        employee {
          id
          name
          email
        }
      }
    }
  }
`;
const CHECK_ALL_RESERVATION = gql`
  mutation($input: checkAllReservationInput) {
    checkAllReservation(input: $input) {
      id
      check {
        id
        name
        employee {
          id
        }
      }
      status {
        id
        name
      }
    }
  }
`;
const RESERVATION_EXPORT = gql`
  mutation($date: Date!, $input: optionInput) {
    reservationBalanceExport(date: $date, input: $input) {
      status
      message
    }
  }
`;

const GET_BOOKING_HISTORY = gql`
  query(
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    reservationsByPeople(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        total {
          id
          show_fare_amount
          show_fare_currency
        }
        balance {
          id
          show_fare_amount
          show_fare_currency
        }
        currency
        created_at
        status {
          id
          name
        }
        products {
          id
          status {
            id
            name
          }
        }
        transactions {
          id
          status {
            id
            name
          }
        }
        reservation_people {
          id
          roles {
            id
            name
          }
        }
        created_by {
          id
          name
          email
        }
      }
      paginatorInfo {
        count
        currentPage
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;
export {
  CREATE_RESERVATION,
  GET_RESERVATION_BY_ID,
  GET_RESERVATIONS,
  DELETE_RESERVATION,
  RESERVATION_STATUS,
  RESERVATION_TASK_CONNECTOR,
  SEND_EMAIL_RESERVATION,
  CHECK_RESERVATION,
  CHECK_ALL_RESERVATION,
  RESERVATION_EXPORT,
  EXPORT_RESERVATION,
  RESERVATION_EXPORT_STATUS,
  GET_BOOKING_HISTORY
};

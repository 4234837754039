import { gql } from "apollo-boost";

const GET_PAYMENT_METHOD = gql`
  {
    paymentMethods {
      id
      name
      online_status
      is_b2c_only
      external_id_regex
      currencies {
        code
        name
        symbol
      }
    }
  }
`;

const CREATE_PAYMENT_METHOD = gql`
  mutation($input: paymentMethodInput) {
    createPaymentMethods(input: $input) {
      id
      name
      external_id_regex
      currencies {
        code
        name
        symbol
      }
    }
  }
`;

const UPDATE_PAYMENT_METHOD = gql`
  mutation($id: ID!, $input: paymentMethodInput) {
    updatePaymentMethods(id: $id, input: $input) {
      id
      name
      external_id_regex
      currencies {
        code
        name
        symbol
      }
    }
  }
`;

const GET_ARRANGER_PAYMENT_METHOD = gql`
  query($reservation_id: ID) {
    arrangerPaymentMethods(reservation_id: $reservation_id) {
      id
      name
      online_status
      external_id_regex
      currencies {
        code
        name
        symbol
      }
      balance
      is_b2c_only
    }
  }
`;

export {
  GET_PAYMENT_METHOD,
  CREATE_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD,
  GET_ARRANGER_PAYMENT_METHOD
};

import React from "react";
import NumberFormat from "react-number-format";
import { TriggerEnterKey } from "../../utils/TriggerKey";
import { round } from "lodash";
import { Row, Col, UncontrolledPopover, PopoverBody } from "reactstrap";

const MoneyInputField = ({
  value,
  decimalScale,
  displayType,
  disabled,
  className = "form-control align-number transaction-home-currency",
  isReadOnly,
  type,
  onBlur,
  isPriceReadOnly,
  showPopup,
  prices,
  onClick,
  isDisable,
  idName
}) => {
  type === "homeCurrency" && (value = round(value, 2));

  return (
    <>
      {idName !== "" ? (
        <NumberFormat
          id={idName}
          thousandSeparator={true}
          decimalSeparator={"."}
          decimalScale={decimalScale}
          fixedDecimalScale={false}
          value={value || 0}
          suffix={""}
          displayType={displayType || "input"}
          onFocus={e => {
            e.target.select();
          }}
          onValueChange={({ value }) => {}}
          disabled={disabled || false}
          className={className}
          readOnly={
            isDisable ? isDisable : isReadOnly || isPriceReadOnly ? true : false
          }
          onBlur={onBlur ? onBlur : () => {}}
          onKeyDown={TriggerEnterKey}
          data-lpignore="true"
        />
      ) : (
        <NumberFormat
          thousandSeparator={true}
          decimalSeparator={"."}
          decimalScale={decimalScale}
          fixedDecimalScale={false}
          value={value || 0}
          suffix={""}
          displayType={displayType || "input"}
          onFocus={e => {
            e.target.select();
          }}
          onValueChange={({ value }) => {}}
          disabled={disabled || false}
          className={className}
          readOnly={
            isDisable ? isDisable : isReadOnly || isPriceReadOnly ? true : false
          }
          onBlur={onBlur ? onBlur : () => {}}
          onKeyDown={TriggerEnterKey}
          data-lpignore="true"
        />
      )}
      <span>{type && type === "homeCurrency" ? " USD" : ""}</span>
      {showPopup && (
        <UncontrolledPopover
          className="rexchangerate-popover"
          target="reservation_exchange_rate"
          trigger="legacy"
          placement="bottom"
        >
          <PopoverBody>
            <Row className="popover-wrapper">
              <Col>
                {prices && prices !== "USD" && (
                  <>
                    <li onClick={() => onClick(prices.buying)}>
                      {prices.buying}
                    </li>
                    <li onClick={() => onClick(prices.selling)}>
                      {prices.selling}
                    </li>
                  </>
                )}
              </Col>
            </Row>
          </PopoverBody>
        </UncontrolledPopover>
      )}
    </>
  );
};

export default MoneyInputField;

import CommonReducer from "../CommonReducer";

const initialState = [];

const ReservationProductReducer = CommonReducer(
  initialState,
  "reservationProduct"
);

export default ReservationProductReducer;

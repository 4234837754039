export const ADD_RESERVATION_PEOPLE = "addreservationPeople";
export const EDIT_RESERVATION_PEOPLE = "updatereservationPeople";
export const REMOVE_RESERVATION_PEOPLE = "removereservationPeople";
export const SET_RESERVATION_PEOPLE = "setreservationPeople";
export const RESET_RESERVATION_PEOPLE = "resetreservationPeople";

export const ADD_RESERVATION_PRODUCT = "addreservationProduct";
export const EDIT_RESERVATION_PRODUCT = "updatereservationProduct";
export const MODIFY_EDIT_RESERVATION_PRODUCT = "modifyreservationProduct";
export const REMOVE_RESERVATION_PRODUCT = "removereservationProduct";
export const SET_RESERVATION_PRODUCT = "setreservationProduct";
export const RESET_RESERVATION_PRODUCT = "resetreservationProduct";
export const ADD_RESERVATION_REFUND_PRODUCT = "addreservationRefundProduct";
export const SET_RESERVATION_REFUND_PRODUCT = "setReservationRefundProduct";

export const ADD_RESERVATION_TRANSACTION = "addreservationTransaction";
export const EDIT_RESERVATION_TRANSACTION = "updatereservationTransaction";
export const REMOVE_RESERVATION_TRANSACTION = "removereservationTransaction";
export const SET_RESERVATION_TRANSACTION = "setreservationTransaction";
export const RESET_RESERVATION_TRANSACTION = "resetreservationTransaction";

export const ADD_RESERVATION_SOURCE = "addreservationSource";
export const EDIT_RESERVATION_SOURCE = "updatereservationSource";
export const REMOVE_RESERVATION_SOURCE = "removereservationSource";
export const SET_RESERVATION_SOURCE = "setreservationSource";
export const RESET_RESERVATION_SOURCE = "resetreservationSource";

export const ADD_RESERVATION_TASK = "addreservationTask";
export const EDIT_RESERVATION_TASK = "updatereservationTask";
export const REMOVE_RESERVATION_TASK = "removereservationTask";
export const SET_RESERVATION_TASK = "setreservationTask";
export const RESET_RESERVATION_TASK = "resetreservationTask";

export const SET_RESERVATION_ID = "setreservationId";
export const SET_RESERVATION_STATUS = "setreservationStatus";
export const SET_RESERVATION_CHECKED_UNCHECKED =
  "setReservationCheckedUnchecked";
export const SET_RESERVATION_ASSIGNTO = "setreservationAssignTo";
export const SET_RESERVATION_ARRANGER = "setreservationArranger";
export const SET_RESERVATION_TOTAL = "setreservationTotal";
export const SET_RESERVATION_BALANCE = "setreservationBalance";
export const SET_RESERVATION_TAB = "setreservationTab";
export const SET_RESERVATION_SUBTAB = "setreservationSubTab";
export const SET_RESERVATION_CHILDSUBTAB = "setreservationChildSubTab";
export const SET_RESERVATION_CURRENCY = "setreservationCurrency";
export const SET_RESERVATION_EXCHANGE_RATE = "setreservationExchangeRate";
export const SET_RESERVATION_DOCSTATUS = "setreservationDocStatus";
export const SET_RESERVATION_EMAILSTATUS = "setreservationEmailStatus";
export const SET_EXCHANGE_RATE_ARRAY = "setexchangeRateArray";
export const SET_RESERVATION_SALESCHANNEL = "setreservationSalesChannel";
export const SET_RESERVATION_LOGS = "setreservationLogs";
export const SET_RESERVATION_TRANSACTION_SAVE = "setreservationTransactionSave";
export const SET_RESERVATION_PRODUCT_SAVE = "setreservationProductSave";

import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./App.scss";
import "./scss/common.scss";
import "react-select/dist/react-select.min.css";
import "./scss/reactselect.scss";
import "./scss/reactphone.scss";
import "./scss/reactpopover.scss";
import "./scss/reactdates.scss";
import { ApolloProvider } from "@apollo/react-hooks";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { AUTH_TOKEN, TOKEN_TYPE } from "./config/constants";
import { createUploadLink } from "apollo-upload-client";
import LoadingSpinner from "./components/LoadingSpinner";
import { retry } from "./utils/RetryLoadable";
import DefaultLayout from "./containers/DefaultLayout";

// const MaintenancePage = React.lazy(() =>
//   retry(() => import("./pages/MaintenancePage"))
// );
const Login = React.lazy(() => retry(() => import("./pages/login/Login")));
const GoogleLogin = React.lazy(() =>
  retry(() => import("./pages/login/GoogleLogin"))
);
const RegisterPeople = React.lazy(() =>
  retry(() => import("./pages/people/RegisterPeople"))
);

const authLink = setContext((_, { headers }) => {
  const tokenType = localStorage.getItem(TOKEN_TYPE);
  const token = localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `${tokenType} ${token}` : ""
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(
    ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(
            ({ debugMessage, message, locations, path }) => {
              if (debugMessage && debugMessage === "Unauthenticated.") {
                localStorage.removeItem(AUTH_TOKEN);
                window.location.href = "/";
              }

              console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
              );
            }
          );
        if (networkError) console.log(`[Network error]: ${networkError}`);
      }),
      createUploadLink({
        uri:
          process.env.REACT_APP_BACKEND_SERVER +
          process.env.REACT_APP_GRAPHQL_SERVER
      })
    ])
  ),
  cache: new InMemoryCache()
});

function App() {
  // if (!window.location.pathname.includes("maintenance")) {
  //   window.location.href = "#/maintenance";
  // }

  return (
    <ApolloProvider client={client}>
      <HashRouter>
        <React.Suspense fallback={<LoadingSpinner />}>
          <Switch>
            {/* <Route
              exact
              path="/maintenance"
              name="Maintenance Page"
              render={props => <MaintenancePage {...props} />}
            />
            <Route path="*" render={props => <MaintenancePage {...props} />} /> */}
            <Route
              exact
              path="/login"
              name="Login Page"
              render={props => <Login {...props} />}
            />
            <Route
              exact
              path="/google-cb"
              name="Login"
              render={props => <GoogleLogin {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register"
              render={props => <RegisterPeople {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={props => <DefaultLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </ApolloProvider>
  );
}

export default App;

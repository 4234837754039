import React from "react";
import { Row, Col } from "reactstrap";
import ReactPaginate from "react-paginate";
import styled from "styled-components";

const ReactPagination = ({
  lastPage,
  handlePagePagination,
  page,
  handleSizePerPagePagination,
  count,
  dataArr,
  total,
  recordName
}) => {
  return (
    <PaginationStyle>
      <Row className="pagi-wrapper">
        <Col xs="12" className="d-flex nowrap align-item-center pb-3">
          <ReactPaginate
            previousLabel={"<<"}
            nextLabel={">>"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={lastPage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePagePagination}
            containerClassName={"common-pagination mb-0"}
            subContainerClassName={"common pages pagination"}
            activeClassName={"active"}
            forcePage={page}
          />
          <select
            className="paginate-select common-custom-perpage"
            onChange={e => handleSizePerPagePagination(e.target.value)}
            value={count}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </select>
          <span
            className="d-inline-block ml-3 mt-2 font-weight-bold"
            style={{ flexGrow: "1" }}
          >
            {dataArr.length} of {total} {recordName}
          </span>
        </Col>
      </Row>
    </PaginationStyle>
  );
};

const PaginationStyle = styled.div`
  .pagi-wrapper {
    margin-top: 15px;
  }

  ul.common-pagination {
    float: left;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;

    li.active {
      a {
        color: #343b41;
        background-color: #20a8d8;
        border-color: #20a8d8;
      }
    }

    li.disabled {
      a {
        color: #73818f;
        pointer-events: none;
        cursor: auto;
        background-color: #23282c;
        border-color: #23282c;
      }
    }

    li {
      a {
        position: relative;
        display: block;
        padding: 0.5rem 0.75rem;
        margin-left: -1px;
        line-height: 1.25;
        color: #20a8d8;
        background-color: #343b41;
        border: 1px solid #23282c;
        cursor: pointer;
      }
    }
  }

  .common-custom-perpage {
    width: 70px !important;
    text-align: center;
    float: left;
    margin-left: 20px;
  }

  .paginate-select {
    background: #515b65 !important;
    border-radius: 4px !important;
    color: #e4e7ea !important;
    border: none !important;
  }
`;

export default ReactPagination;

import {
  ADD_RESERVATION_TRANSACTION,
  EDIT_RESERVATION_TRANSACTION,
  REMOVE_RESERVATION_TRANSACTION,
  SET_RESERVATION_TRANSACTION,
  RESET_RESERVATION_TRANSACTION
} from "./types";

const addReservationTransaction = transaction => {
  return { type: ADD_RESERVATION_TRANSACTION, payload: transaction };
};

const editReservationTransaction = (transaction, editIndex) => {
  return {
    type: EDIT_RESERVATION_TRANSACTION,
    payload: { data: transaction, editIndex: editIndex }
  };
};

const setReservationTransaction = transaction => {
  return { type: SET_RESERVATION_TRANSACTION, payload: transaction };
};

const removeReservationTransaction = editIndex => {
  return {
    type: REMOVE_RESERVATION_TRANSACTION,
    payload: editIndex
  };
};

const resetReservationTransaction = () => {
  return { type: RESET_RESERVATION_TRANSACTION };
};

export {
  addReservationTransaction,
  editReservationTransaction,
  setReservationTransaction,
  removeReservationTransaction,
  resetReservationTransaction
};

import { debounce } from "lodash";

let debounceFunc = () => {};
let typingTimer = null;

export const DebounceForSelect = (
  val,
  setOptionsArr,
  setSearchVal,
  setSearchText,
  getOptions
) => {
  clearTimeout(typingTimer);
  typingTimer = setTimeout(() => {
    if (val !== "") {
      setOptionsArr([]);
      setSearchVal(val);
      getOptions(val);
      setSearchText("Searching...");
    }
  }, 2000);
};

import CommonReducer from "../CommonReducer";

const initialState = [];

const ReservationTransactionReducer = CommonReducer(
  initialState,
  "reservationTransaction"
);

export default ReservationTransactionReducer;

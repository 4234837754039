import {
  ADD_RESERVATION_SOURCE,
  EDIT_RESERVATION_SOURCE,
  REMOVE_RESERVATION_SOURCE,
  SET_RESERVATION_SOURCE,
  RESET_RESERVATION_SOURCE
} from "./types";

const addReservationSource = source => {
  return { type: ADD_RESERVATION_SOURCE, payload: source };
};

const editReservationSource = (source, editIndex) => {
  return {
    type: EDIT_RESERVATION_SOURCE,
    payload: { data: source, editIndex: editIndex }
  };
};

const setReservationSource = source => {
  return { type: SET_RESERVATION_SOURCE, payload: source };
};

const removeReservationSource = editIndex => {
  return {
    type: REMOVE_RESERVATION_SOURCE,
    payload: editIndex
  };
};

const resetReservationSource = () => {
  return { type: RESET_RESERVATION_SOURCE };
};

export {
  addReservationSource,
  editReservationSource,
  setReservationSource,
  removeReservationSource,
  resetReservationSource
};

import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { useLazyQuery } from "@apollo/react-hooks";
import { GET_CAMPAIGNS } from "../../queries/SourceQuery";
import { find } from "lodash";
import { DebounceForSelect } from "../../utils/DebounceForSelect";

const CampaignSelectField = ({
  name,
  value,
  valueName,
  handleChange,
  onBlur
}) => {
  const [searchVal, setSearchVal] = useState("");
  const [searchText, setSearchText] = useState("Please type to search...");
  const [flag, setFlag] = useState(true);
  const [optionsArr, setOptionsArr] = useState([]);

  const [getCampaign] = useLazyQuery(GET_CAMPAIGNS, {
    fetchPolicy: "network-only",
    onCompleted: res => {
      let arr =
        res.manualCampaigns.data.length > 0
          ? res.manualCampaigns.data.map(option => ({
              value: option.id,
              label: option.name
            }))
          : [];

      arr.length === 0 && setSearchText("No result found.");
      setOptionsArr(arr);
    },
    onError: err => {
      console.log(err);
    }
  });

  const getOptions = useCallback(
    value => {
      getCampaign({
        variables: {
          first: 50,
          page: 1,
          filter: [
            {
              field: "name",
              operator: "like",
              value: value
            }
          ]
        }
      });
    },
    [getCampaign]
  );

  useEffect(() => {
    flag && getOptions(searchVal);
    setFlag(false);
  }, [flag, getOptions, searchVal]);

  searchVal === "" &&
    value !== "" &&
    !find(optionsArr, val => val.value === value) &&
    optionsArr.push({ value: value, label: valueName });

  return (
    <Select
      options={optionsArr}
      name={name}
      value={value}
      onChange={e => handleChange && handleChange(e ? e.value : "")}
      className="common-select"
      onBlur={onBlur ? onBlur : () => {}}
      onInputChange={val =>
        DebounceForSelect(
          val,
          setOptionsArr,
          setSearchVal,
          setSearchText,
          getOptions
        )
      }
      clearable={false}
      placeholder="Please type to search..."
      noResultsText={searchText}
      onFocus={() =>
        searchText === "No result found." &&
        optionsArr.length === 0 &&
        setSearchText("Please type to search...")
      }
    />
  );
};

export default CampaignSelectField;

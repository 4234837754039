import { gql } from "apollo-boost";

const GET_SOURCE_TYPE = gql`
  query($filter: [filterInput], $first: Int!, $page: Int) {
    sources(
      filter: $filter
      first: $first
      page: $page
      orderBy: [{ field: "id", order: DESC }]
    ) {
      data {
        id
        items {
          id
          key
          value
        }
      }
      paginatorInfo {
        count
        currentPage
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const DEL_SOURCE_ITEM = gql`
  mutation($id: ID!) {
    deleteSource(id: $id) {
      id
    }
  }
`;

const DELETE_SOURCE = gql`
  mutation($id: ID!) {
    deleteReservationSource(id: $id) {
      id
    }
  }
`;

const GET_SALES_CHANNEL = gql`
  query($filter: [filterInput], $first: Int!, $page: Int) {
    salesChannels(
      filter: $filter
      first: $first
      page: $page
      orderBy: [{ field: "id", order: DESC }]
    ) {
      data {
        id
        name
      }
      paginatorInfo {
        count
        currentPage
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const GET_CAMPAIGNS = gql`
  query($filter: [filterInput], $first: Int!, $page: Int) {
    manualCampaigns(
      filter: $filter
      first: $first
      page: $page
      orderBy: [{ field: "id", order: DESC }]
    ) {
      data {
        id
        name
        start_date
        end_date
        default
      }
      paginatorInfo {
        count
        currentPage
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const CREATE_CAMPAIGNS = gql`
  mutation($input: campaignCreateInput) {
    createCampaign(input: $input) {
      id
      name
      start_date
      end_date
    }
  }
`;

const UPDATE_CAMPAIGNS = gql`
  mutation($input: campaignEditInput) {
    editCampaign(input: $input) {
      id
      name
      start_date
      end_date
    }
  }
`;

const DELETE_CAMPAIGNS = gql`
  mutation($includeIds: [ID!]!) {
    bulkDeleteCampaign(includeIds: $includeIds) {
      id
    }
  }
`;

export {
  GET_SOURCE_TYPE,
  DEL_SOURCE_ITEM,
  DELETE_SOURCE,
  GET_SALES_CHANNEL,
  GET_CAMPAIGNS,
  CREATE_CAMPAIGNS,
  UPDATE_CAMPAIGNS,
  DELETE_CAMPAIGNS
};

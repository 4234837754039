import { gql } from "apollo-boost";

const GET_EXCHANGE_RATE = gql`
  query($active: Boolean) {
    exchangeRates(active: $active) {
      code
      buying
      selling
      is_active
      last_updated
    }
  }
`;

const GET_EXCHANGE_RATE_BY_CODE = gql`
  query($code: ID!) {
    exchangeRatesByCode(code: $code) {
      code
      buying
      selling
      is_active
      last_updated
    }
  }
`;

const EDIT_EXCHANGE_RATE = gql`
  mutation($input: exchangeRateInput) {
    editExchangeRate(input: $input) {
      code
      buying
      selling
      is_active
      last_updated
    }
  }
`;

const ACTIVATE_EXCHANGE_RATE = gql`
  mutation($id: ID!, $is_active: Boolean) {
    activateExchangeRate(id: $id, is_active: $is_active) {
      code
      buying
      selling
      is_active
      last_updated
    }
  }
`;

const EXCHANGE_RATE_HISTORY = gql`
  {
    exchangeRateList {
      date
      currencies {
        code
        buying
        selling
        buying_at
        selling_at
        is_active
        last_updated
      }
    }
  }
`;

const GET_EXCHANGE_RATE_BY_DATE = gql`
  query($code: String!, $date: Date!) {
    exchangeRateByDate(code: $code, date: $date) {
      code
      buying
      selling
      is_active
      last_updated
    }
  }
`;
const GET_EXCHANGE_RATE_BY_DATETIME = gql`
  query exchangeRateByDateTime($code: String!, $date_time: DateTime!) {
    exchangeRateByDateTime(code: $code, date_time: $date_time) {
      code
      buying
      selling
      is_active
      last_updated
    }
  }
`;

export {
  GET_EXCHANGE_RATE,
  GET_EXCHANGE_RATE_BY_CODE,
  EDIT_EXCHANGE_RATE,
  ACTIVATE_EXCHANGE_RATE,
  EXCHANGE_RATE_HISTORY,
  GET_EXCHANGE_RATE_BY_DATE,
  GET_EXCHANGE_RATE_BY_DATETIME
};

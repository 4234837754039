import React, {
  Suspense,
  useEffect,
  useState,
  useCallback,
  useRef
} from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { GET_USER_BY_ID } from "../queries/UserQuery";
import { useLazyQuery } from "@apollo/react-hooks";
import { LOGIN_USER_ID, IDENTIFIER } from "../config/constants";
import { Button, Modal, ModalBody, ModalFooter, Container } from "reactstrap";
import {
  AppBreadcrumb,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav
} from "@coreui/react";
import DefaultHeaderDropdown from "./DefaultHeaderDropdown";
import { connect } from "react-redux";
// sidebar nav config
import Nav from "../_nav";
import NavSuper from "../_navsuper";
// routes config
import routes from "../routes";
import { isAuthenticated } from "../lib/auth";
import LoadingSpinner from "../components/LoadingSpinner";
import styled from "styled-components";
import {
  EMPTY_TAB_ID,
  RESERVATION_DEFAULT_CURRENCY
} from "../config/reservations";
import {
  setReservationId,
  setReservationStatus,
  setReservationCheckedUnchecked,
  setReservationAssignTo,
  setReservationArranger,
  setReservationSalesChannel,
  setReservationLogs,
  setReservationTotal,
  setReservationBalance,
  setReservationTab,
  setReservationCurrency,
  setReservationExchangeRate,
  setReservationEmailStatus,
  setreservationTransactionSave,
  setreservationProductSave
} from "../actions/ReservationAction";
import { resetReservationPeople } from "../actions/ReservationPeopleAction";
import { resetReservationProduct } from "../actions/ReservationProductAction";
import { resetReservationSource } from "../actions/ReservationSourceAction";
import { resetReservationTask } from "../actions/ReservationTaskAction";
import { resetReservationTransaction } from "../actions/ReservationTransactionAction";

const DefaultLayout = props => {
  const [userRole, setUserRole] = useState(null);
  const [getUsers] = useLazyQuery(GET_USER_BY_ID, {
    onCompleted: response => {
      response.user &&
        response.user.roles &&
        response.user.roles.length > 0 &&
        setUserRole(response.user.roles[0].id);
      response.user.roles.length > 0 &&
        localStorage.setItem("userRole", response.user.roles[0].id);
      localStorage.setItem("userName", response?.user?.email);
    },
    onError: err => {
      console.log(err);
    }
  });
  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current)
      getUsers({
        variables: {
          id: localStorage.getItem(LOGIN_USER_ID)
        }
      });
  }, [getUsers]);
  const [isOpen, setIsOpen] = useState(false);
  const [isReservation, setIsReservation] = useState(false);
  const [isChangeUrl, setIsChangeUrl] = useState(false);
  const [nav, setNav] = useState(NavSuper);

  let checkDirty = data => {
    if (data === undefined) {
      return false;
    }
    let obj = JSON.parse(JSON.stringify(data));
    let returnValue = false;
    Object.keys(obj).map((key, index) => {
      obj[key] = obj[key].filter(item => item && item.id === null);
      obj[key].map(item => {
        if (Object.keys(item).length > 1) {
          returnValue = true;
        }
        return true;
      });
      return true;
    });
    return returnValue;
  };

  const handleLeave = useCallback(() => {
    //set state empty
    props.dispatch(resetReservationProduct());
    props.dispatch(resetReservationPeople());
    props.dispatch(resetReservationTransaction());
    props.dispatch(resetReservationSource());
    props.dispatch(resetReservationTask());
    props.dispatch(setReservationId(null));
    props.dispatch(setReservationStatus(null));
    props.dispatch(setReservationAssignTo(null));
    props.dispatch(setReservationCheckedUnchecked(null));
    props.dispatch(setReservationArranger(null));
    props.dispatch(setReservationSalesChannel(null));
    props.dispatch(setReservationLogs([]));
    props.dispatch(setReservationTab(EMPTY_TAB_ID));
    props.dispatch(
      setReservationEmailStatus({
        eticket: false,
        reserve: false
      })
    );
    props.dispatch(
      setReservationTotal({ home_amount: 0, show_fare_amount: 0 })
    );
    props.dispatch(
      setReservationBalance({ home_amount: 0, show_fare_amount: 0 })
    );
    props.dispatch(setReservationCurrency(RESERVATION_DEFAULT_CURRENCY));
    props.dispatch(setReservationExchangeRate(1));
    props.dispatch(setreservationTransactionSave(false));
    props.dispatch(setreservationProductSave(false));

    setIsOpen(false);
  }, [props]);

  let isdirty = checkDirty(props.data);
  useEffect(() => {
    let currentUrl = props.location.pathname.split("/");
    if (
      currentUrl.length > 0 &&
      (currentUrl[1] !== "reservations" ||
        (currentUrl[1] === "reservations" && !currentUrl[2]))
    ) {
      setIsChangeUrl(true);
      isdirty && isChangeUrl && isReservation && handleLeave();
    } else {
      setIsChangeUrl(false);
    }
    if (userRole === "1") {
      let superAdminNav = NavSuper;

      if (
        ![
          "joost.de.kruijff@flymya.co",
          "htut.khaung.kyaw.oo@flymya.co",
          "myat.thu.thu.htoon@flymya.co",
          "naing.ye.oo@flymya.co"
        ].includes(localStorage.getItem(IDENTIFIER))
      ) {
        superAdminNav = {
          items: NavSuper.items.map(i => {
            if (i.name === "Setup") {
              i.children = i.children.filter(i => i.name !== "Permissions");
            }
            return i;
          })
        };
      }
      setNav(superAdminNav);
    } else {
      setNav(Nav);
    }
    return () => {
      if (
        currentUrl.length > 0 &&
        ((currentUrl[1] === "reservations" && currentUrl[2] === "create") ||
          (currentUrl[1] === "reservations" && currentUrl[2] === "edit"))
      ) {
        setIsReservation(true);
        isdirty && isChangeUrl && isReservation && handleLeave();
      } else {
        if (!isdirty && isChangeUrl && isReservation) {
          handleLeave();
        }
        setIsReservation(false);
      }
    };
  }, [isdirty, isReservation, props, handleLeave, userRole, isChangeUrl]);

  const handleStay = () => {
    props.history.goBack();
    setIsOpen(false);
  };

  useEffect(() => {
    let currentUrl = props.location.pathname.split("/");
    if (
      currentUrl.length > 0 &&
      currentUrl[1] === "reservations" &&
      currentUrl[2] === "edit"
    ) {
      routes.map(r => {
        r.path === "/reservations/edit/:id" && (r.name = currentUrl[3]);
        return r;
      });
    }
  }, [props.location.pathname]);

  return (
    <DefaultLayoutStyle>
      <div className="app">
        <Modal isOpen={isOpen}>
          <ModalBody>
            You have unsaved changes. Are you sure you want to leave without
            saving??
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleStay}>
              Stay
            </Button>{" "}
            <Button color="secondary" onClick={handleLeave}>
              Leave
            </Button>
          </ModalFooter>
        </Modal>

        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense>
              <AppSidebarNav navConfig={nav} location={props.location} />
            </Suspense>
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <div className="top-header">
              <AppBreadcrumb appRoutes={routes} />
              <DefaultHeaderDropdown onLogout={props.onLogout} accnt />
            </div>
            <Container fluid>
              <Suspense fallback={<LoadingSpinner />}>
                <Switch>
                  {routes.map((route, idx) => {
                    if (!isAuthenticated()) {
                      return <Redirect key={idx} to="/login" />;
                    }

                    return route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => <route.component {...props} />}
                      />
                    ) : null;
                  })}
                  <Redirect from="/" to="/reservations" />
                </Switch>
              </Suspense>
            </Container>
          </main>
        </div>
      </div>
    </DefaultLayoutStyle>
  );
};

const DefaultLayoutStyle = styled.div`
  .top-header {
    width: 100%;
    display: flex;
    background-color: #3a4149;
    height: 46px;
    margin: 0 0 0 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  }

  .top-header .dropdown.nav-item {
    margin-left: auto;
    display: flex;
  }

  .top-header .dropdown.nav-item img {
    max-width: 50px;
    max-height: 30px;
  }
`;

const mapStateToProps = state => {
  return {
    data: {
      reservationPeople: state.reservationPeople,
      reservationProduct: state.reservationProduct,
      reservationTransaction: state.reservationTransaction
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);

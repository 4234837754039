import { find } from "lodash";
const GetNameFromArray = (arr, typeId) => {
  let getName = find(arr, o => arr && o.id === (typeId === 20 ? 21 : typeId));
  return getName ? getName.name : "";
};

const FindDataFromArray = (arr, editId) =>
  find(arr, val => Number(val.id) === Number(editId));

const GetSelectObjectArray = (arr, filter) => {
  return arr && arr.length > 0
    ? arr.map(item => ({
        value: filter ? item.name : item.id,
        label: item.name
      }))
    : [];
};

export { GetNameFromArray, GetSelectObjectArray, FindDataFromArray };

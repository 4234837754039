import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import {
  toMySqlDate,
  formatDate,
  toMySqlDateTime,
  formatDateTime,
  formatDateTimeSec
} from "../../utils/DateFormat";
import { GET_EXCHANGE_RATE } from "../../queries/ExchangeRateQuery";
import { useQuery } from "@apollo/react-hooks";
import { GET_VALID_DATE } from "../../queries/DateQuery";
import moment from "moment";

const DatePickerField = ({
  name,
  fieldName,
  value,
  placeholder,
  id,
  handleBlur,
  setFieldValue,
  values,
  dispatch = null,

  isDisable,
  highlightItem,
  isModal,
  className,
  fieldNameYup,
  pageName,
  page,
  getExchangeRate,
  getIssuedExchangeRate,
  code
}) => {
  const [val, setVal] = useState(null);
  const [maxDate, setMaxDate] = useState(new Date());
  const [type, setType] = useState("PRODUCT");
  const [startDate, setStartDate] = useState(
    setHours(setMinutes(new Date(), 30), 16)
  );

  useEffect(() => {
    if (page === "product") {
      setType("PRODUCT");
    } else if (page === "transaction") {
      setType("TRANSACTION");
    }
  }, [type, page]);

  const { error, data, refetch } = useQuery(GET_VALID_DATE, {
    fetchPolicy: "network-only",
    variables: {
      valid_type: type
    }
  });

  useEffect(() => {
    if (data?.validDate) {
      setMaxDate(data?.validDate?.valid_date);
    }
  }, [data]);

  return (
    <DatePicker
      selected={startDate}
      minDate={new Date(maxDate)}
      minTime={new Date(maxDate)}
      // maxTime={setHours(setMinutes(new Date(), 90), 23.9)}
      maxTime={moment()
        .endOf("day")
        .toDate()}
      onChange={value => {
        if (val === null) {
          if (fieldName.includes(".")) {
            let field = fieldName.split(".");
            values[field[0]][field[1]] =
              fieldName === "issued_date" ||
              fieldName === "date_of_sale" ||
              fieldName === "payment_date"
                ? toMySqlDateTime(value)
                : toMySqlDate(value);
            setStartDate(value);
          } else {
            values[fieldName] =
              fieldName === "issued_date" ||
              fieldName === "date_of_sale" ||
              fieldName === "payment_date"
                ? toMySqlDateTime(value)
                : toMySqlDate(value);
            setStartDate(value);
          }
          if (dispatch === null) {
            handleBlur(values);
          } else {
            dispatch !== null && dispatch();
            handleBlur();
          }
        }
        setFieldValue(
          fieldName,
          fieldName === "issued_date" ||
            fieldName === "date_of_sale" ||
            fieldName === "payment_date"
            ? toMySqlDateTime(value)
            : toMySqlDate(value)
        );
        value !== undefined &&
          fieldName === "payment_date" &&
          getExchangeRate({
            variables: {
              code: "MMK",
              date_time: toMySqlDateTime(value)
            }
          });
        // value !== undefined &&
        //   fieldName === "date_of_sale" &&
        //   getExchangeRate({
        //     variables: {
        //       code: code ? code : "MMK",
        //       date_time: toMySqlDateTime(value)
        //     }
        //   });
        // value !== undefined &&
        //   fieldName === "issued_date" &&
        //   getIssuedExchangeRate({
        //     variables: {
        //       code: code ? code : "MMK",
        //       date_time: toMySqlDateTime(value)
        //     }
        //   });
      }}
      onSelect={value => {
        setVal(null);
        setFieldValue(
          fieldName,
          fieldName === "issued_date" ||
            fieldName === "date_of_sale" ||
            fieldName === "payment_date" ||
            fieldName === "deadline"
            ? toMySqlDateTime(value)
            : toMySqlDate(value)
        );
      }}
      value={
        val !== null
          ? val
          : fieldName === "issued_date" ||
            fieldName === "date_of_sale" ||
            fieldName === "payment_date"
          ? formatDateTime(values[fieldName])
          : fieldName === "deadline"
          ? formatDateTimeSec(values[fieldName])
          : formatDate(values[fieldName] ? values[fieldName] : value)
      }
      onChangeRaw={e => {
        setVal(e.target.value);
        setFieldValue(
          fieldName,
          fieldName === "issued_date" ||
            fieldName === "date_of_sale" ||
            fieldName === "payment_date" ||
            fieldName === "deadline"
            ? toMySqlDateTime(e.target.value)
            : toMySqlDate(e.target.value)
        );
      }}
      placeholderText={placeholder}
      selectsRange={true}
      id={id}
      name={name}
      showTimeSelect={
        fieldName === "issued_date" ||
        fieldName === "date_of_sale" ||
        fieldName === "payment_date" ||
        fieldName === "deadline"
          ? true
          : false
      }
      className={`form-control ${highlightItem ? highlightItem : ""} ${
        className ? className : ""
      }`}
      timeFormat="HH:mm"
      timeIntervals={5}
      autoComplete="off"
      popperPlacement={
        isModal || fieldName === "start_date" || fieldName === "end_date"
          ? "bottom"
          : "top-start"
      }
      popperModifiers={{
        flip: {
          behavior: ["bottom"] // don't allow it to flip to be above
        },
        preventOverflow: {
          enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
        },
        hide: {
          enabled: false // turn off since needs preventOverflow to be enabled
        }
      }}
      readOnly={isDisable ? isDisable : false}
    />
  );
};

export default DatePickerField;

import { gql } from "apollo-boost";

const GET_CASHBACK = gql`
  query(
    $first: Int!
    $page: Int
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
  ) {
    cashback(orderBy: $orderBy, first: $first, page: $page, filter: $filter) {
      data {
        id
        cashback
        source_fee
        source_fee_currency
        source_fee_limit
        limit
        amount
        currency
        booking_date_from
        booking_date_to
        travel_date_from
        travel_date_to
        email
        phone
        operator_name
        operator_segment
        citizen
        used
        payment_method
        product_type
        min_spend_amount
        min_spend_amount_currency
        email_usage_limit
        promo_supplier {
          id
          name
        }
        description
        manual_campaign {
          id
          name
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const CREATE_CASHBACK = gql`
  mutation createCashback($input: cashbackInput) {
    createCashback(input: $input) {
      id
      cashback
      source_fee
      source_fee_currency
      source_fee_limit
      limit
      amount
      currency
      booking_date_from
      booking_date_to
      travel_date_from
      travel_date_to
      email
      phone
      operator_name
      operator_segment
      citizen
      payment_method
      product_type
      min_spend_amount
      min_spend_amount_currency
      description
      promo_supplier {
        id
        name
      }
      employee {
        id
        email
      }
    }
  }
`;

const DELETE_CASHBACK = gql`
  mutation deleteCashback($id: ID!) {
    deleteCashback(id: $id) {
      id
      cashback
      source_fee
      source_fee_currency
      source_fee_limit
      employee {
        id
        name
      }
      limit
      amount
      currency
      booking_date_from
      booking_date_to
      travel_date_from
      travel_date_to
      email
      phone
      operator_name
      operator_segment
      citizen
      payment_method
      product_type
      min_spend_amount
      min_spend_amount_currency
      description
      used
      one_time_usage
      email_usage_limit
      promo_supplier {
        id
        name
      }
      manual_campaign {
        id
        name
      }
    }
  }
`;

export { GET_CASHBACK, CREATE_CASHBACK, DELETE_CASHBACK };
